import { Box, TextField, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import React, { useState, useEffect } from "react"
import { UI_TYPE } from "../../UiTypes";
import { handleDoneKeyPress } from "../../utils/ui-utils";
import { isValidIdString } from "./field-utils"
import AbcIcon from '@mui/icons-material/Abc';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import NumbersIcon from '@mui/icons-material/Numbers';
import EditIcon from '@mui/icons-material/Edit';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

export const EditText = ({uiType, compToEdit = null, formFieldOrder, onIsValidChange, onValueChange, editableIsMandatory}) => {
  const [id, setId] = useState((compToEdit?.key != null) ? compToEdit?.key : null)
  const [label, setLabel] = useState((compToEdit?.object?.label != null) ? compToEdit?.object?.label : null)
  const [editable, setEditable] = useState((compToEdit?.object?.settings?.editable != null) ? compToEdit?.object?.settings?.editable : true)
  const [min, setMin] = useState(compToEdit?.object?.settings?.minLength)
  const [max, setMax] = useState(compToEdit?.object?.settings?.maxLength)
  const [autoExpandHeight, setAutoExpandHeight] = useState(compToEdit?.object?.settings?.autoExpandHeight)
  const invalidId = id && id.length > 0 && !isValidIdString(id)
  const isShortTextOrInput = [UI_TYPE.TEXT_INPUT_SHORT, UI_TYPE.TEXT_SHORT].includes(uiType);

  useEffect(() => {
    onValueChange({key: id, object: {uiType: uiType, ...(label && (label.length > 0) && {label: label}), settings:{editable: editable, ...(editable && min && {minLength: min}), ...(editable && max && {maxLength: max}), ...(!isShortTextOrInput && (autoExpandHeight != null) && {autoExpandHeight: autoExpandHeight})}}})
    onIsValidChange(id && id.length > 0 && !invalidId && (compToEdit || !formFieldOrder || formFieldOrder.length == 0 || !formFieldOrder.includes(id)))
  }, [id, label, editable, min, max, autoExpandHeight]);

  return <Box sx={{display:'flex', flexDirection:'column', gap:'14px'}}>
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <Box sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><FingerprintIcon fontSize='small' />ID for element*</Box>
    {!compToEdit && <TextField id="input-id" variant="standard" error={invalidId} helperText={invalidId ? "Invalid characters" : null} sx={{fontSize:'14px'}} value={id || ""} onChange={(ev) => setId(ev.target.value)} />}
    {compToEdit && <Typography sx={{my:'8px', fontFamily: 'Monospace'}}>{id || ""}</Typography>}
  </Box>
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <Box sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><AbcIcon fontSize='small' />Label to show</Box>
    <TextField id="input-label" variant="standard" sx={{fontSize:'14px'}} value={label || ""} onChange={(ev) => setLabel(ev.target.value)} inputProps={{ enterKeyHint: 'done' }} onKeyDown={handleDoneKeyPress} />
  </Box>
  {!editableIsMandatory && <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <FormControl>
    <FormLabel sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><EditIcon fontSize='small' />Allow user to edit*</FormLabel>
    <RadioGroup
      row
      name="editable-radio-buttons-group"
      value={editable ? "yes" : "no"}
      onChange={(ev) => setEditable(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
      <FormControlLabel value="no" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>
  </Box>}
  {editable && <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <Box sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><NumbersIcon fontSize='small' />Input rules</Box>
    <Box sx={{display:'flex', flexDirection:'row', alignItems:'baseline', gap:'6px'}}>
      <TextField id="input-min" variant="standard" label="Min. length" sx={{fontSize:'14px'}} value={min || ""} onChange={(ev) => setMin(isNaN(parseInt(ev.target.value)) ? null : parseInt(ev.target.value))} />
      <TextField id="input-max" variant="standard" label="Max. length" sx={{fontSize:'14px'}} value={max || ""} onChange={(ev) => setMax(isNaN(parseInt(ev.target.value)) ? null : parseInt(ev.target.value))} inputProps={{ enterKeyHint: 'done' }} onKeyDown={handleDoneKeyPress} />
    </Box>
  </Box>}
  {!isShortTextOrInput && <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
  <FormControl>
    <FormLabel sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><UnfoldMoreIcon fontSize='small' />Expanding to full height</FormLabel>
    <RadioGroup
      row
      name="autoExpandHeight-radio-buttons-group"
      value={autoExpandHeight ? "yes" : "no"}
      onChange={(ev) => setAutoExpandHeight(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Always expand" />
      <FormControlLabel value="no" control={<Radio />} label="Show button to expand" />
    </RadioGroup>
  </FormControl>
  </Box>}
</Box>
}