import { Box, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React, { useState, useEffect } from "react";
import TextFieldsIcon from '@mui/icons-material/TextFields';

export const EditStaticText = ({uiType, compToEdit = null, onIsValidChange, onValueChange}) => {
  const [text, setText] = useState(compToEdit?.object?.value)
  const [textSize, setTextSize] = useState(compToEdit?.object?.settings?.textSize || "m")
  useEffect(() => {
    onValueChange({key: compToEdit?.key || Date.now()+"", object: {uiType: uiType, value: text?.trim(), settings:{textSize: textSize}}})
    onIsValidChange(text && (text.trim().length > 0))
  }, [text, textSize]);

  return <Box sx={{display:'flex', flexDirection:'column', gap:'14px'}}>
      <Box sx={{px:'8px', py:'6px', width:'360px', maxWidth:'100%', background: '#f1f1f1', borderRadius:'6px'}}>
      <TextField id="input-static-text" variant="standard" label="Enter text" multiline maxRows={8} fullWidth sx={{fontSize:'14px'}} value={text || ""} onChange={(ev) => setText(ev.target.value)} />
    </Box>
    <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <FormControl>
      <FormLabel sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><TextFieldsIcon fontSize='small' />Text size</FormLabel>
      <RadioGroup
        row
        name="textSize-radio-buttons-group"
        value={textSize}
        onChange={(ev) => setTextSize(ev.target.value)}
      >
        <FormControlLabel value="s" control={<Radio />} label="S" />
        <FormControlLabel value="m" control={<Radio />} label="M" />
        <FormControlLabel value="l" control={<Radio />} label="L" />
        <FormControlLabel value="xl" control={<Radio />} label="XL" />
      </RadioGroup>
    </FormControl>
    </Box>
  </Box>
}