import { Box, TextField, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React, { useState, useEffect } from "react";
import { UI_TYPE } from "../../UiTypes";
import { handleDoneKeyPress } from "../../utils/ui-utils";
import { isValidIdString } from "./field-utils";
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ListIcon from '@mui/icons-material/List';
import NumbersIcon from '@mui/icons-material/Numbers';

export const EditOptionsSelect = ({uiType, compToEdit = null, formFieldOrder, onIsValidChange, onValueChange}) => {
  const optionalMultiSelect = [UI_TYPE.BUTTON_SELECT].includes(uiType)
  const alwaysMultiSelect = [UI_TYPE.CHECKBOXES].includes(uiType)
  const allowStyling = uiType === UI_TYPE.BUTTON_SELECT;
  const [id, setId] = useState((compToEdit?.key != null) ? compToEdit.key : "")
  const [required, setRequired] = useState((compToEdit?.object?.required != null) ? compToEdit?.object?.required : true)
  const [min, setMin] = useState(compToEdit?.object?.settings?.minSelect)
  const [max, setMax] = useState(compToEdit?.object?.settings?.maxSelect)
  const [allowOptMultiSelect, setAllowOptMultiSelect] = useState((compToEdit?.object?.settings?.multiSelect != null) ? compToEdit?.object?.settings?.multiSelect : false)
  const [fixedChoices, setFixedChoices] = useState(!compToEdit || compToEdit.object.values != null)
  const [choice1, setChoice1] = useState((compToEdit?.object?.values && compToEdit?.object?.values.length > 0) ? compToEdit?.object?.values[0] : null)
  const [choice2, setChoice2] = useState((compToEdit?.object?.values && compToEdit?.object?.values.length > 1) ? compToEdit?.object?.values[1] : null)
  const [choice3, setChoice3] = useState((compToEdit?.object?.values && compToEdit?.object?.values.length > 2) ? compToEdit?.object?.values[2] : null)
  const [choice4, setChoice4] = useState((compToEdit?.object?.values && compToEdit?.object?.values.length > 3) ? compToEdit?.object?.values[3] : null)
  const invalidId = id && (id.length > 0) && !isValidIdString(id)
  const canMultiSelect = alwaysMultiSelect || (optionalMultiSelect && allowOptMultiSelect)

  const isValidChoice = (choice) => {
    return choice && choice.id && (choice.id.trim().length > 0) && choice.label && (choice.label.trim().length > 0)
  }

  useEffect(() => {
    onValueChange({key: id, object: {uiType: uiType, required: required, settings: {...(optionalMultiSelect && {multiSelect: allowOptMultiSelect}), ...(required && canMultiSelect && min && {minSelect: min}), ...(max && canMultiSelect && {maxSelect: max})}, ...(fixedChoices && {values: [...(isValidChoice(choice1) ? [choice1] : []),...(isValidChoice(choice2) ? [choice2] : []),...(isValidChoice(choice3) ? [choice3] : []),...(isValidChoice(choice4) ? [choice4] : [])]})}})
    onIsValidChange(id && (id.length > 0) && !invalidId && (compToEdit || !formFieldOrder || formFieldOrder.length == 0 || !formFieldOrder.includes(id)) && (!fixedChoices || (isValidChoice(choice1) || isValidChoice(choice2) || isValidChoice(choice3) || isValidChoice(choice4))))
  }, [id, required, min, max, allowOptMultiSelect, fixedChoices, choice1, choice2, choice3, choice4]);

  return <Box sx={{display:'flex', flexDirection:'column', fontSize:'12px', gap:'14px'}}>
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <Box sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><FingerprintIcon fontSize='small' />ID for element*</Box>
    {!compToEdit && <TextField id="input-id" variant="standard" error={invalidId} helperText={invalidId ? "Invalid characters" : null} sx={{fontSize:'14px'}} value={id || ""} onChange={(ev) => setId(ev.target.value)} inputProps={{ enterKeyHint: 'done' }} onKeyDown={handleDoneKeyPress} />}
    {compToEdit && <Typography sx={{my:'8px', fontFamily: 'Monospace'}}>{id || ""}</Typography>}
  </Box>
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
  <FormControl>
    <FormLabel sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><LocalPoliceIcon fontSize='small' />Selection required*</FormLabel>
    <RadioGroup
      row
      name="required-radio-buttons-group"
      value={required ? "yes" : "no"}
      onChange={(ev) => setRequired(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
      <FormControlLabel value="no" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>
  </Box>
  {optionalMultiSelect && !compToEdit && <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <FormControl>
    <FormLabel sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><ChecklistIcon fontSize='small' />Allow multi-select*</FormLabel>
    <RadioGroup
      row
      name="multiSelect-radio-buttons-group"
      value={allowOptMultiSelect ? "yes" : "no"}
      onChange={(ev) => setAllowOptMultiSelect(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
      <FormControlLabel value="no" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>
  </Box>}
  {optionalMultiSelect && compToEdit && <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <Box sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><ChecklistIcon fontSize='small' />Allow multi-select</Box>
    <Typography sx={{my:'8px'}}>{allowOptMultiSelect ? "Yes" : "No"}</Typography>
  </Box>}
  {canMultiSelect && <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <Box sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><NumbersIcon fontSize='small' />Selection rules</Box>
    <Box sx={{display:'flex', flexDirection:'row', alignItems:'baseline', gap:'6px'}}>
    {required && <TextField id="input-min" variant="standard" label="Min. selections" sx={{fontSize:'14px'}} value={min || "1"} onChange={(ev) => setMin(isNaN(parseInt(ev.target.value)) ? null : parseInt(ev.target.value))} />}
    <TextField id="input-max" variant="standard" label="Max. selections" sx={{fontSize:'14px'}} value={max || ""} onChange={(ev) => setMax(isNaN(parseInt(ev.target.value)) ? null : parseInt(ev.target.value))} inputProps={{ enterKeyHint: 'done' }} onKeyDown={handleDoneKeyPress} />
  </Box>
  </Box>}
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
  <FormControl>
    <FormLabel sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><ListIcon fontSize='small' />Options available*</FormLabel>
    <RadioGroup
      row
      name="fixedChoices-radio-buttons-group"
      value={fixedChoices ? "yes" : "no"}
      onChange={(ev) => setFixedChoices(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Fixed options" />
      <FormControlLabel value="no" control={<Radio />} label="Send with review request" />
    </RadioGroup>
  </FormControl>
  </Box>
  {fixedChoices && <><Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}><Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, alignItems:'baseline', gap:'6px'}}>
    <Typography variant="inherit" color='secondary' sx={{px:'0px'}}>Option&nbsp;1*</Typography>
    <TextField id="input-id1" variant="standard" label="ID*" sx={{fontSize:'14px'}} value={choice1?.id || ""} onChange={(ev) => setChoice1(prev => {return {...(prev && prev), id: ev.target.value}})} />
    <TextField id="input-label1" variant="standard" label="Label*" sx={{fontSize:'14px'}} value={choice1?.label || ""} onChange={(ev) => setChoice1(prev => {return {...(prev && prev), label: ev.target.value}})} />
    {allowStyling && <TextField id="input-style1" variant="standard" label="Style (accept | reject | '')" sx={{fontSize:'14px'}} value={choice1?.style || ""} onChange={(ev) => setChoice1(prev => {return {...(prev && prev), style: ev.target.value}})} />}
  </Box></Box>
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}><Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, alignItems:'baseline', gap:'6px'}}>
    <Typography variant='inherit' color='secondary' sx={{px:'0px'}}>Option&nbsp;2&nbsp;</Typography>
    <TextField id="input-id2" variant="standard" label="ID" sx={{fontSize:'14px'}} value={choice2?.id || ""} onChange={(ev) => setChoice2(prev => {return {...(prev && prev), id: ev.target.value}})} />
    <TextField id="input-label2" variant="standard" label="Label" sx={{fontSize:'14px'}} value={choice2?.label || ""} onChange={(ev) => setChoice2(prev => {return {...(prev && prev), label: ev.target.value}})} />
    {allowStyling && <TextField id="input-style2" variant="standard" label="Style (accept | reject | '')" sx={{fontSize:'14px'}} value={choice2?.style || ""} onChange={(ev) => setChoice2(prev => {return {...(prev && prev), style: ev.target.value}})} />}
  </Box></Box>
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}><Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, alignItems:'baseline', gap:'6px'}}>
    <Typography variant='inherit' color='secondary' sx={{px:'0px'}}>Option&nbsp;3&nbsp;</Typography>
    <TextField id="input-id3" variant="standard" label="ID" sx={{fontSize:'14px'}} value={choice3?.id || ""} onChange={(ev) => setChoice3(prev => {return {...(prev && prev), id: ev.target.value}})} />
    <TextField id="input-label3" variant="standard" label="Label" sx={{fontSize:'14px'}} value={choice3?.label || ""} onChange={(ev) => setChoice3(prev => {return {...(prev && prev), label: ev.target.value}})} />
    {allowStyling && <TextField id="input-style3" variant="standard" label="Style (accept | reject | '')" sx={{fontSize:'14px'}} value={choice3?.style || ""} onChange={(ev) => setChoice3(prev => {return {...(prev && prev), style: ev.target.value}})} />}
  </Box></Box>
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}><Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, alignItems:'baseline', gap:'6px'}}>
    <Typography variant='inherit' color='secondary' sx={{px:'0px'}}>Option&nbsp;4&nbsp;</Typography>
    <TextField id="input-id4" variant="standard" label="ID" sx={{fontSize:'14px'}} value={choice4?.id || ""} onChange={(ev) => setChoice4(prev => {return {...(prev && prev), id: ev.target.value}})} />
    <TextField id="input-label4" variant="standard" label="Label" sx={{fontSize:'14px'}} value={choice4?.label || ""} onChange={(ev) => setChoice4(prev => {return {...(prev && prev), label: ev.target.value}})} {...(!allowStyling && {  inputProps: { enterKeyHint: 'done' }, onKeyDown: handleDoneKeyPress })} />
    {allowStyling && <TextField id="input-style4" variant="standard" label="Style (accept | reject | '')" sx={{fontSize:'14px'}} value={choice4?.style || ""} onChange={(ev) => setChoice4(prev => {return {...(prev && prev), style: ev.target.value}})} inputProps={{ enterKeyHint: 'done' }} onKeyDown={handleDoneKeyPress} />}
  </Box></Box></>}
</Box>
}