import React, { useState, useEffect, useContext } from 'react';
import {Outlet, useParams, useNavigate, useLocation} from 'react-router-dom';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, LinearProgress, Rating, Link, Paper, ToggleButtonGroup, ToggleButton, Button, Tooltip, Popover, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, DialogActions, Switch } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DoneIcon from '@mui/icons-material/Done';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import StarsIcon from '@mui/icons-material/Stars';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Data from './Data';
import { AccountContext, UserContext } from './AccountFrame';
import { catchError } from 'rxjs';
import FormEditor from './FormEditor';
import { handleDoneKeyPress } from './utils/ui-utils';

const BRANDING_ADD = "add";
const BRANDING_REMOVE = "remove";

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { account } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  const [cycleUsage, setCycleUsage] = useState();
  const [loadingCheckoutSession, setLoadingCheckoutSession] = useState(false);
  const [loadingPortalSession, setLoadingPortalSession] = useState(false);
  const [userIdEntered, setUserIdEntered] = useState("");
  const [roleValue, setRoleValue] = useState("viewer");
  const [brandingChangeTrigger, setBrandingChangeTrigger] = useState();
  const [userAddTrigger, setUserAddTrigger] = useState(false);
  const [userIdAdded, setUserIdAdded] = useState();
  const [userAddError, setUserAddError] = useState();
  const userIdEnteredAndValid = userIdEntered.length && !userIdEntered.includes("@")
  
  const isDialogHashPresent = location.hash === '#d';
  const openUserAdd = isDialogHashPresent && location.state && location.state.type === 'user';
  
  const role = (!user || !user.access || !account) ? null : user.access[account.path];
  const isDevOrAdmin = (!!role && ['dev', 'admin'].includes(role));
  const isAdminForAccount = (!!role && ['admin'].includes(role));
  const isOnFreePlan = (!account.plan || !account.plan.code || account.plan.code.startsWith('free'));

  useEffect(() => {
    Data.getInstance().getReviewCountInCycle(account.id)
    .then((response) => {
        setCycleUsage(response)
    })
    .catch((err) => {
        console.error("getReviewCountInCycle",err);
    });
  }, []);

  useEffect(() => {
    console.log('loadingCheckoutSession ' + loadingCheckoutSession);
    if (loadingCheckoutSession) {
      Data.getInstance().createCheckoutSession(account.id)
      .then((response) => {
         console.log("redirectUrl", response);
        //  window.open(response.sessionUrl)
         window.location.href = response.sessionUrl
      })
      .catch((err) => {
         console.error("loadingCheckoutSession",err);
         setLoadingCheckoutSession(false)
      });
    } else {
      setLoadingCheckoutSession(false)
    }
  }, [loadingCheckoutSession]);

  useEffect(() => {
    console.log('loadingPortalSession ' + loadingPortalSession);
    if (loadingPortalSession) {
      Data.getInstance().createPortalSession(account.id)
      .then((response) => {
         console.log("redirectUrl", response);
        //  window.open(response.sessionUrl)
         window.location.href = response.sessionUrl
      })
      .catch((err) => {
         console.error("loadingPortalSession",err);
         setLoadingPortalSession(false)
      });
    } else {
      setLoadingPortalSession(false)
    }
  }, [loadingPortalSession]);

  useEffect(() => {
    console.log('userAddTrigger ' + userAddTrigger);
    if (userAddTrigger && userIdEnteredAndValid) {
      setUserIdEntered("");
      setUserIdAdded();
      setUserAddError();
      Data.getInstance().addUserToAccount(account.path, userIdEntered, roleValue)
      .then((data) => {
         console.log(data);
         setUserIdAdded(userIdEntered);
         setUserAddTrigger(false)
         // navigate("/", {replace: true})
      })
      .catch((err) => {
         console.log(err.message);
         setUserAddError("Error: Could not add user!");
         setUserAddTrigger(false)
      });
    } else {
      setUserAddTrigger(false)
    }
  }, [userAddTrigger]);

  useEffect(() => {
    console.log('brandingChangeTrigger ' + brandingChangeTrigger);
    if (!brandingChangeTrigger) return () => {}
    
    Data.getInstance().changeBrandingSettingForAccount(account.path, brandingChangeTrigger === BRANDING_ADD)
    .then(() => {
       console.log("changed branding setting");
       setBrandingChangeTrigger(null)
    })
    .catch((err) => {
       console.log(err.message);
       setBrandingChangeTrigger(null)
    });
  }, [brandingChangeTrigger]);

  const handleCloseUserAdd = () => {
    if (isDialogHashPresent)
      navigate(-1);
  };

  const handleClickOpenUserAdd = (e) => {
    e.preventDefault();
    navigate('#d', { state: { type: 'user' } });
  };

  const onChangeHideBranding = (checked) => {
    setBrandingChangeTrigger(checked ? BRANDING_ADD : BRANDING_REMOVE);
  }

  return (
    <div>
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      px: {xs: '0px', sm: '24px'},
      paddingTop: {xs: '14px', lg: '40px'},
      paddingBottom: '60px',
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%', maxWidth: '1160px', mb:'20px' }}>
        <Typography variant="h4" sx={{ marginBottom: '6px', fontWeight: '600' }}>Settings</Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: {xs: 'column', lg: 'row'},
        width:'90%',
        maxWidth:'1160px',
        gap: '40px',
      }}>
        <Paper sx={{ width: '100%', background: '#FBFBFB', borderRadius: '10px', px: '28px', py: '32px' }}>
          {!isDevOrAdmin && <Typography variant='subtitle2' sx={{mb: '24px', color:'#114B5F'}}>Please ask a dev or admin to invite users or change any settings</Typography> }
          <Typography variant='h5' sx={{mb:'16px'}}>Users</Typography>
          <Button variant='outlined' startIcon={<PersonAddIcon/>} disabled={!isDevOrAdmin} onClick={handleClickOpenUserAdd}>Add user</Button>
          <Typography variant='h5' sx={{mb:'16px', mt:'32px'}}>Branding</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap', alignItems:'center' }}>
            <Typography variant="subtitle1" sx={{ mr:'8px' }}>Hide branding in public links</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems:'center' }}>
              <Switch
                disabled={isOnFreePlan || !isDevOrAdmin || brandingChangeTrigger}
                checked={brandingChangeTrigger ? (brandingChangeTrigger === BRANDING_ADD) : (account?.settings?.hideBranding || false)}
                onChange={event => onChangeHideBranding(event.target.checked)}
              />
              {isOnFreePlan && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems:'center', textDecoration:'none', border:'1px solid #E26D5C', borderRadius:'12px', px:'10px', py:'2px' }}>
                <LockOpenIcon sx={{fontSize:'14px', color: '#E26D5C'}} />
                <Typography variant="subtitle2" sx={{ ml:'8px', color:'#E26D5C', textDecoration:'none' }}>Upgrade to unlock</Typography>
              </Box>}
            </Box>
          </Box>
          <Typography variant='h5' sx={{mb:'16px', mt:'32px'}}>Usage</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap', alignItems:'center' }}>
            <Typography variant="subtitle1" sx={{ mr:'20px' }}>Current cycle started:</Typography>
            {cycleUsage && <Typography variant="subtitle1" sx={{ fontFamily:'monospace' }}>{new Date(cycleUsage.cycleStartedAt).toLocaleString()}</Typography>}
            {!cycleUsage && <CircularProgress size={16} />}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap', alignItems:'center' }}>
            <Typography variant="subtitle1" sx={{ mr:'20px' }}>Reviews requested since:</Typography>
            {cycleUsage && <Typography variant="subtitle1" sx={{ fontFamily:'monospace' }}>{cycleUsage.reviewCount}</Typography>}
            {!cycleUsage && <CircularProgress size={16} />}
          </Box>
          <Box id='subscription' sx={{ mt:'32px'}}>
            <Typography variant='h5' sx={{mb:'16px'}}>Subscription</Typography>
            <Box sx={{display: 'flex', flexDirection: 'row', flexWrap:'wrap', alignItems:'start', gap:'18px' }}>
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center', width:'260px', border:'1px solid #ccc', borderRadius:'6px', background:'#f1f1f1', px:'18px', py:'12px'}}>
                <Typography variant='subtitle1' sx={{mb:'16px'}}>FREE</Typography>
                <Typography variant='body2' sx={{mb:'8px'}}>Limited reviews</Typography>
                <Typography variant='body2' sx={{mb:'8px'}}>Max. 100 reviews / month</Typography>
                {isOnFreePlan && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems:'center', cursor:'default', mt:'12px', border:'1px solid #888', borderRadius:'18px', px:'10px', py:'8px' }}>
                  <DoneIcon sx={{fontSize:'14px'}} />
                  <Typography variant="subtitle2" sx={{ ml:'8px', color:'#888' }}>Your plan</Typography>
                </Box>}
                {!isOnFreePlan && <Button variant='outlined' color='darkPetrol' disabled={true} sx={{ display: 'flex', flexDirection: 'row', alignItems:'center', mt:'12px', textDecoration:'none', border:'1px solid #888', borderRadius:'18px', px:'10px', py:'8px' }}>
                  <ArrowDownwardIcon sx={{fontSize:'14px'}} />
                  <Typography variant="subtitle2" sx={{ ml:'8px', color:'#888' }}>Cancel subscription to downgrade</Typography>
                </Button>}
              </Box>
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center', width:'260px', border:'1px solid '+ (!isOnFreePlan ? '#cfdbd5' : '#ccc'), borderRadius:'6px', backgroundImage: "radial-gradient(circle,#cfdbd5 20%,#fff0 100%)", px:'18px', py:'12px'}}>
                <Typography variant='subtitle1' sx={{color:'#E26D5C', mb:'16px'}}>PRO</Typography>
                <Typography variant='body2' sx={{mb:'8px'}}>Unlimited reviews</Typography>
                <Typography variant='body2' sx={{mb:'8px'}}>Incl. 300 reviews / month</Typography>
                <Typography variant='body2' sx={{mb:'8px'}}>Beyond: $29 / 300 reviews</Typography>
                <Typography variant='body2' sx={{mb:'8px'}}>Target specific users</Typography>
                <Typography variant='body2' sx={{mb:'8px'}}>Escalation flows (soon)</Typography>
                <Typography variant='body2' sx={{mb:'8px'}}>Response data set</Typography>
                <Typography variant='body2' sx={{mb:'8px'}}>Monitoring metrics</Typography>
                <Typography variant='body2' sx={{mb:'8px'}}>Remove branding</Typography>
                <Typography variant='body2' sx={{mb:'8px'}}>Priority support</Typography>
                {!isOnFreePlan && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems:'center', mt:'12px', border:'1px solid #E26D5C', borderRadius:'18px', px:'18px', py:'8px' }}>
                  <DoneIcon sx={{fontSize:'14px', color: '#E26D5C'}} />
                  <Typography variant="subtitle2" sx={{ ml:'8px', color:'#E26D5C', cursor:'default' }}>Your plan</Typography>
                </Box>}
                {/* {isOnFreePlan && <Button variant='contained' component={Link} href="#subscription" disabled={!isAdminForAccount} color='rose' sx={{ display: 'flex', flexDirection: 'row', alignItems:'center', mt:'12px', textDecoration:'none', borderRadius:'18px', px:'18px', py:'8px' }}>
                  <StarsIcon sx={{fontSize:'14px', color: '#fff'}} />
                  <Typography variant="subtitle2" sx={{ ml:'8px', color:'#fff' }}>Upgrade to Pro</Typography>
                </Button>} */}
                {isOnFreePlan && <Button onClick={()=> setLoadingCheckoutSession(true)} variant='contained' startIcon={loadingCheckoutSession ? <CircularProgress size={16} color='whitey' /> : <StarsIcon/>} disabled={!isAdminForAccount || loadingCheckoutSession} color='rose' sx={{ display: 'flex', flexDirection: 'row', alignItems:'center', mt:'12px', textDecoration:'none', borderRadius:'18px', px:'18px', py:'8px' }}>
                Upgrade to Pro
                </Button>}
              </Box>
            </Box>
            {!isOnFreePlan && <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap', alignItems:'center', mt:'20px' }}>
            <Typography variant="subtitle1" sx={{ mr:'20px' }}>Manage your subscription and payment:</Typography>
            <Button onClick={()=> setLoadingPortalSession(true)} variant='contained' startIcon={loadingPortalSession ? <CircularProgress size={16} color='whitey' /> : <SettingsIcon/>} disabled={!isAdminForAccount || loadingPortalSession} sx={{ display: 'flex', flexDirection: 'row', alignItems:'center', textDecoration:'none', borderRadius:'18px', px:'18px', py:'8px' }}>
                Subscription Portal
              </Button>
            </Box>}
            {!isAdminForAccount && <Typography variant="subtitle2" sx={{ mt:'8px' }}>Please ask an admin if you want to change your subscription</Typography>}
            </Box>
        </Paper>
      </Box>
    </Box>
      
      {account && <Dialog open={openUserAdd} onClose={handleCloseUserAdd}>
        <DialogTitle>Invite User to project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add another user to your project, ask the person to sign up and pass you their user id.
            They'll be notified once added.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="User ID"
            fullWidth
            variant="standard"
            value={userIdEntered}
            onChange={(ev) => setUserIdEntered(ev.target.value)}
            inputProps={{ enterKeyHint: 'done' }} onKeyDown={handleDoneKeyPress}
          />
          <FormControl>
            <FormLabel>Role:</FormLabel>
            <RadioGroup
              row
              name="role-radio-buttons-group"
              value={roleValue}
              onChange={(ev) => setRoleValue(ev.target.value)}
            >
              <FormControlLabel value="viewer" control={<Radio />} label="Viewer" />
              <FormControlLabel value="dev" control={<Radio />} label="Dev" />
              {(user && user.access && (user.access[account.path] === "admin")) && <FormControlLabel value="admin" control={<Radio />} label="Admin" />}
            </RadioGroup>
          </FormControl>
          {userIdAdded && <Box sx={{color:'green'}}>Added user {userIdAdded}</Box>}
          {userAddError && <Box sx={{color:'red'}}>{userAddError}</Box>}
          {userIdEntered.length > 0 && !userIdEnteredAndValid && <Box sx={{color:'red'}}>Please add the user's user ID, not their email!</Box>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUserAdd}>Dismiss</Button>
          <Button 
          disabled={!userIdEnteredAndValid || userAddTrigger} 
          onClick={() => {setUserIdAdded(null); setUserAddTrigger(true);}}
          startIcon={userAddTrigger ? <CircularProgress size={24} /> : <PersonAddIcon/>}
          >Add User</Button>
        </DialogActions>
      </Dialog>}
    </div>
  )
};

export default Settings;