import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, ListItemButton, Divider, Tooltip, Popover, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, DialogActions, Button } from '@mui/material';
import Data from './Data';
import CircularProgress from '@mui/material/CircularProgress';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { Link } from 'react-router-dom';

const AccountCard = ({account, user}) => {
  const navigate = useNavigate();
  const [anchorKeyEl, setAnchorKeyEl] = useState(null);
  const [anchorAccSwitcherEl, setAnchorAccSwitcherEl] = useState(null);
  // const [key, setKey] = useState();

  const openKey = Boolean(anchorKeyEl);
  const idKey = openKey ? 'simple-popover' : undefined;
  /* const role = (!user || !user.access || !account) ? null : user.access[account.path];
  const isDevOrAdmin = !!role && ['dev', 'admin'].includes(role);
  const isAdminForAccount = !!role && ['admin'].includes(role); */

  /* useEffect(() => {
    setKey(null);
    if (!isDevOrAdmin || !account) return;
    const subscription = Data.getInstance().fetchApiKey(`Accounts/${account.id}`)
        .subscribe((key) => setKey(key));
    return () => {
      subscription.unsubscribe();
    };
  }, [account, isDevOrAdmin]); */

  const handleClickGotoSettings = (event) => {
    event.preventDefault();
    navigate(`/Accounts/${account.id}/Settings`);
  }

  const handleClickSwitchAccount = (event) => {
    event.preventDefault();
    setAnchorAccSwitcherEl(event.currentTarget);
  }

  const handleAccSwitcherClose = () => {
    setAnchorAccSwitcherEl(null);
  }

  const handleKeyClick = (event) => {
    event.preventDefault();
    setAnchorKeyEl(event.currentTarget);
  };

  const handleKeyClose = () => {
    setAnchorKeyEl(null);
  };

  let keyBtn = "";
  /* if (key && account) {
    keyBtn = (
      <div>
        <Tooltip title="Show API key">
          <IconButton color="inherit" onClick={handleKeyClick}>
            <VpnKeyOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id={idKey}
          open={openKey}
          anchorEl={anchorKeyEl}
          onClose={handleKeyClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{display: 'flex', alignItems: 'center', padding: '10px'}}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{paddingRight:'6px'}}><b>Your API key:</b> {key}</Typography>
                <Typography sx={{paddingRight:'6px'}}><b>Your Org Id:</b> {account.id}</Typography>
              </Box>
            </Box>
          </Box>
        </Popover>
      </div>
    );
  } */
  {/* <Button onClick={() => copy(key)}>{showCopied ? 'Copied \u2713' : 'Copy'}</Button> */}
  let accountsPopover = "";
  const openAccSwitcher = Boolean(anchorAccSwitcherEl);
  const idAccSwitcher = openAccSwitcher ? 'acc-switcher-popover' : undefined;
  if (user.accounts && user.accounts.length > 1) {
    accountsPopover = (
      <Popover
        id={idAccSwitcher}
        open={openAccSwitcher}
        anchorEl={anchorAccSwitcherEl}
        onClose={handleAccSwitcherClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{display: 'flex', flexDirection:'column', gap:'6px', alignItems:'start', padding:'10px'}}>
        <Typography variant='caption' sx={{}}>Switch account:</Typography>
          {user.accounts.filter(acc => acc.id !== account.id).map(acc => 
            <Button key={acc.id} color="inherit" onClick={() => {handleAccSwitcherClose(); navigate(`/Accounts/${acc.id}`);}}>{acc.name}</Button>
          )}
        </Box>
      </Popover>
    )
  }

  return (
      <Box sx={{ display: 'flex', flexDirection: {xs: 'row', lg: 'row'}, width:{xs:'100%', sm:'400px'}, maxWidth:'100%', alignItems: {xs: 'center', lg: 'center'}, justifyContent:'space-between', border: 0, borderRadius:'10px', borderColor: '#aaa', marginBottom:{xs: '10px', lg: '0px'}, padding: {xs:'2px', lg:'4px'}, color:'white', background:'#3B7080' }}>
        <Box sx={{ display: 'flex', flex: 1 , color:'#CFDBD5'}}>
          <Tooltip title={account.name} placement='bottom-start'><Typography sx={{ flex:1, minHeight:'34px', lineHeight:'34px', marginLeft:'8px', marginRight:'8px', textWrap:'nowrap', overflow:'hidden', color:'white', letterSpacing:'0.01em', fontSize:'16px', fontWeight:'500' }} >{account.name.substring(0,20)}</Typography></Tooltip>
          {(user.accounts && user.accounts.length > 1) && (<div><Tooltip title="Switch account">
            <IconButton sx={{color:'inherit'}} onClick={handleClickSwitchAccount}><CompareArrowsIcon /></IconButton>
          </Tooltip>
          {accountsPopover}
          </div>)}
        </Box>
        <Box sx={{ display: 'flex', gap: '8px', color:'#CFDBD5' }}>
          {/* {user && (<Tooltip title="Add users to project">
            <IconButton sx={{color:'inherit'}} onClick={handleClickOpenUserAdd}><PersonAddIcon /></IconButton>
          </Tooltip>)} */}
          <Tooltip title="Settings">
            <IconButton sx={{color:'inherit'}} onClick={handleClickGotoSettings}><SettingsIcon /></IconButton>
          </Tooltip>
          {/* {keyBtn} */}
          
        </Box>
      </Box>
  )
};

export default AccountCard;