import { Box, TextField, Typography } from "@mui/material"
import React, { useState, useEffect } from "react";
import { handleDoneKeyPress } from "../../utils/ui-utils";
import { isValidIdString } from "./field-utils";
import FingerprintIcon from '@mui/icons-material/Fingerprint';

export const EditIdOnlyType = ({uiType, compToEdit = null, formFieldOrder, onIsValidChange, onValueChange}) => {
  const [id, setId] = useState((compToEdit?.key != null) ? compToEdit?.key : null)
  const invalidId = id && (id.length > 0) && !isValidIdString(id)

  useEffect(() => {
    onValueChange({key: id, object: {uiType: uiType}})
    onIsValidChange(id && (id.length > 0) && !invalidId && (compToEdit || !formFieldOrder || formFieldOrder.length == 0 || !formFieldOrder.includes(id)))
  }, [id]);

  return <Box sx={{display:'flex', flexDirection:'column', gap:'14px'}}>
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <Box sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><FingerprintIcon fontSize='small' />ID for element*</Box>
    {!compToEdit && <TextField id="input-id" variant="standard" error={invalidId} helperText={invalidId ? "Invalid characters" : null} sx={{fontSize:'14px'}} value={id || ""} onChange={(ev) => setId(ev.target.value)} inputProps={{ enterKeyHint: 'done' }} onKeyDown={handleDoneKeyPress} />}
    {compToEdit && <Typography sx={{my:'8px', fontFamily: 'Monospace'}}>{id || ""}</Typography>}
  </Box>
</Box>
}