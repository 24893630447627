import React from "react"

import JSONPretty from 'react-json-pretty';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import prism from 'react-syntax-highlighter/dist/esm/styles/prism/prism';
SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('python', python);

export const PyCodeBox = React.memo(({apikey, formId, obj}) => {
  function codeValue(val) {
    /* if (Array.isArray(val)) {
      let attrStrs = []
      for (const [attr, value] of Object.entries(val))
        attrStrs.push(`\t\t${codeValue(value)}`)
      
      return attrStrs.length ? ("[\n" + attrStrs.join(',\n') + "\n\t]") : ""
    } else if (typeof val === 'object') {
      let attrStrs = []
      for (const [attr, value] of Object.entries(val))
        attrStrs.push(`\t\t${attr}: ${codeValue(value)}`)
      
      return attrStrs.length ? ("{\n" + attrStrs.join(',\n') + "\n\t}") : ""
    } else */
      return JSON.stringify(val, undefined, 4)
  }
  const jsonString = obj ? JSON.stringify(obj, undefined, 4) : ""
  let codestring = `gotoHuman = GotoHuman()\nconst review = gotoHuman.create_review(\"${formId}\")\n`
  if (obj?.fields) {
    for (const [attr, val] of Object.entries(obj.fields))
    codestring += `review.add_field_data(\"${attr}\", ${codeValue(val)})\n`
  }
  if (obj?.meta) {
    for (const [attr, val] of Object.entries(obj.meta))
    codestring += `review.add_meta_data(\"${attr}\", ${((typeof val === 'string') ? '\"' : '')+val+((typeof val === 'string') ? '\"' : '')})\n`
  }
  codestring += `review.assign_to_users([\"jess@acme.org\"])\n`
  codestring += "response = review.send_request()"
  return <SyntaxHighlighter language="python" style={prism}>
    {codestring}
  </SyntaxHighlighter>
})

export const TsCodeBox = React.memo(({apikey, formId, obj}) => {
  function codeValue(val) {
    /* if (Array.isArray(val)) {
      let attrStrs = []
      for (const [attr, value] of Object.entries(val))
        attrStrs.push(`\t\t${codeValue(value)}`)
      
      return attrStrs.length ? ("[\n" + attrStrs.join(',\n') + "\n\t]") : ""
    } else if (typeof val === 'object') {
      let attrStrs = []
      for (const [attr, value] of Object.entries(val))
        attrStrs.push(`\t\t${attr}: ${codeValue(value)}`)
      
      return attrStrs.length ? ("{\n" + attrStrs.join(',\n') + "\n\t}") : ""
    } else */
      return JSON.stringify(val, undefined, 4)
  }
  const jsonString = obj ? JSON.stringify(obj, undefined, 4) : ""
  let codestring = `const gotoHuman = new GotoHuman()\nconst review = gotoHuman.createReview(\"${formId}\")\n`
  if (obj?.fields) {
    for (const [attr, val] of Object.entries(obj.fields))
    codestring += `\t.addFieldData(\"${attr}\", ${codeValue(val)})\n`
  }
  if (obj?.meta) {
    for (const [attr, val] of Object.entries(obj.meta))
    codestring += `\t.addMetaData(\"${attr}\", ${((typeof val === 'string') ? '\"' : '')+val+((typeof val === 'string') ? '\"' : '')})\n`
  }
  codestring += `\t.assignToUsers([\"jess@acme.org\"])\n`
  codestring += "const response = await review.sendRequest()"
  return <SyntaxHighlighter language="js" style={prism}>
    {codestring}
  </SyntaxHighlighter>
})

export const ApiJsonBox = React.memo(({obj}) => {
  return <JsonBox obj={{...obj, assignTo: ["jess@acme.org"]}} />
})

export const JsonBox = React.memo(({obj}) => {
  const jsonString = obj ? JSON.stringify(obj, undefined, 4) : ""
  return <JSONPretty id="json-pretty" data={jsonString} style={{fontSize:'0.8em', overflow:'auto'}}></JSONPretty>
  /* return <SyntaxHighlighter language="json" style={prism}>
    {jsonString}
  </SyntaxHighlighter> */
})