import React, {useState, useEffect, createContext, useContext, useCallback} from 'react';
import { useNavigate, useLocation, Navigate, Link } from 'react-router-dom';
import { CardContent, Typography, Grid, Card, Box, TextField, Button, CircularProgress, useMediaQuery, useTheme, Paper, LinearProgress, List } from '@mui/material';
import { catchError, map } from 'rxjs/operators';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Data from './Data';
import { AccountContext, UserContext } from './AccountFrame';
import FormListItem from './FormListItem';
import FirebaseManager from './FirebaseManager';

const FormsList = () => {
  const { account } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [shownFormsCount, setShownFormsCount] = useState(10);
  const [forms, setForms] = useState();
  const [formToClone, setFormToClone] = useState();
  const role = (user && user.id === 'hkURy9h9YnM3JhvZW3sxCZStPd03') ? 'gthAdmin' : ((!user || !user.access || !account) ? null : user.access[account.path]);
  const isDevOrAdmin = !!role && ['dev', 'admin', 'gthAdmin'].includes(role);
  const isMediumScreenOrLess = useMediaQuery(useTheme().breakpoints.down("lg"))

  useEffect(() => {
    if (!isDevOrAdmin) return () => { };
    // console.log("streamForms " + `Accounts/${account.id}`)
    setLoading(true);
    setError(null);
    setForms(null)
    const subscription = Data.getInstance().streamForms({ accPath: `Accounts/${account.id}`})
      .pipe(
        map(forms => forms.filter(form => !form.deleted))
      ).subscribe({
        next: (forms) => {
          console.log('showing Forms.', forms);
          setForms(forms)
          setLoading(false);
        },
        error: (err) => {
          console.error('Error loading Forms.', err);
          setError('There was an error loading the forms.');
          setLoading(false);
        }
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [account]);

  useEffect(() => {
    if (!formToClone) return () => { };
    let clone = { ...formToClone, name: `${formToClone.name} (copy)` };
    delete clone.docId;
    delete clone.createdAt;
    delete clone.modifiedAt;
    const subscription = Data.getInstance().addForm(account.id, clone)
    .subscribe({
      next: (newId) => {
        console.log('cloned form', formToClone.docId);
        setFormToClone(null);
        FirebaseManager.trackEvent("clonedForm")
      },
      error: (err) => {
        console.error('Error cloning form.', err);
        setFormToClone(null);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [account, formToClone]);

  const handleListItemClick = useCallback((itemClicked) => {
    console.log("handleListItemClick " + itemClicked.docId)
    navigate(`/accounts/${account.id}/forms/${itemClicked.docId}`);
  }, []);

  const handleCreateClick = useCallback(() => {
    navigate('new')
  }, []);

  const handleCloneClick = useCallback((form) => {
    setFormToClone(form);
  }, []);

  return (<Box sx={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }}>
    <Box sx={{
      width:'90%',
      maxWidth:'1160px',
      px: {xs: '0px', sm: '24px'},
      paddingTop: {xs: '14px', lg: '40px'},
      paddingBottom: '60px',
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb:'20px' }}>
        <Typography variant="h4" sx={{ marginBottom: '16px', fontWeight: '600' }}>Your Review Forms</Typography>
        <Button sx={{alignSelf:'start', marginBottom:'24px'}} variant='contained' color="accent" startIcon={<AddCircleOutlineIcon />} onClick={handleCreateClick}>Create New Form</Button>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        // width: {xs: '90%', lg: '70%'},
        // maxWidth: {xs: '800px', lg: '860px'},
        alignItems: 'center',
      }}>
        
        <Paper sx={{ width: '100%', background: '#FBFBFB', minHeight:'60px', borderRadius: '10px', paddingTop: '6px', paddingBottom: '6px' }}>
          {loading && <LinearProgress color="secondary" />}
          {error && <Box sx={{ color: 'red', padding: '10px', textAlign: 'center' }}>{error}</Box>}
          {forms && !forms.length && <Box sx={{px: '14px', py:'40px'}}>
            {!forms.length && <Box sx={{ color: '#aaa', textAlign: 'center', fontSize:{xs:'12px', sm:'14px'} }}>No forms created yet</Box>}
          </Box>}
          {forms && !!forms.length && <List sx={{ paddingTop: '0px', paddingBottom: '0px', }}>
            {forms.slice(0, shownFormsCount).map((form, index) => {
              return <Box key={form.docId}>
                <FormListItem form={form} inclDivider={index > 0} isCloningDocId={formToClone?.docId} onItemClick={handleListItemClick} onCloneClick={handleCloneClick} />
                </Box>
            })}
          </List>}
          {forms && (forms.length > shownFormsCount) && <Box sx={{ display: 'flex', marginLeft: '30px', marginTop: '10px', marginBottom: '10px' }}>
            <Typography variant="subtitle2" sx={{ color: '#71717a' }}>+ {forms.length - shownFormsCount} more</Typography>
            <Typography sx={{ display: 'inline', paddingLeft: '14px', fontSize: '14px', color: '#bbb', cursor: 'pointer' }} onClick={() => setShownFormsCount(val => val + 10)}>Show more</Typography>
          </Box>}
        </Paper>
      </Box>
    </Box>
  </Box>)
  /* return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '80px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '40px',
      textAlign: 'center',
    }}>
      FORMSLIST

      <Link to='123'>ID</Link>
      <Link to='new'>NEW</Link>

    </Box>
  ); */
};

export default FormsList;
