import { Box, Typography, Button } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';
import { mapUiTypeToIcon, mapUiTypeToName } from "../formFields/field-utils"
import React from "react"
import { UI_TYPES_SHOW_DATA, UI_TYPE, UI_TYPES_COLLECT_INPUT } from "../../UiTypes";

const ComponentItem = ({uiType, onCompAddClick}) => {
  return <Button startIcon={mapUiTypeToIcon(uiType)} sx={{fontWeight: '600', pl:'18px', border:'1px solid #ccc', textAlign:'start', borderRadius:'8px', width: '100%', maxWidth:'200px', mb:'6px', background:'#CFDBD5'}} onClick={()=> onCompAddClick(uiType)}>
    <Box sx={{width:'100%', textAlign:'left', pl:'6px', textWrap:'nowrap', overflow:'hidden'}}>{mapUiTypeToName(uiType)}</Box>
    </Button>
}

export const AvailableFieldsList = React.memo(({onCompAddClick}) => {

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    width: {xs: '100%', md: '250px', lg: '460px'},
    // height:'100%',
    // minHeight: '400px',
    maxHeight:'540px',
    overflowY: 'auto',
    overflowX: 'hidden',
    pr: {xs: '10px', md:'16px', lg: '40px'},
    borderRight: {xs: '0px', md: '1px solid #ccc'},
    '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 4,},'::-webkit-scrollbar': {width: '4px'},
  }}>
    <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row', md: 'column'}, gap: '20px'}}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', flex:1 }}>
        <Typography variant="subtitle2" sx={{ marginBottom: '10px' }}>Show text & other content</Typography>
        <Grid container columnSpacing={1}>
          {UI_TYPES_SHOW_DATA.filter(el => ![UI_TYPE.HEADER, UI_TYPE.FILE_LINK, UI_TYPE.URL_LINK].includes(el)).map((comp,i) => <Grid key={'dyn'+i} xs={6}sm={12} lg={6}><ComponentItem uiType={comp} onCompAddClick={onCompAddClick} /></Grid>)}
        </Grid>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', flex:1}}>
        <Typography variant="subtitle2" sx={{ marginBottom: '10px' }}>Collect user input</Typography>
        <Grid container columnSpacing={1}>
          {UI_TYPES_COLLECT_INPUT.map((comp,i) => <Grid key={i} xs={6}sm={12}lg={6}><ComponentItem uiType={comp} onCompAddClick={onCompAddClick}/></Grid>)}
        </Grid>
      </Box>
    </Box>
  </Box>
})