import { Box, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import Data from "../../Data";
import React, { useState, useEffect } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const DropDownMenu = React.memo(({accountId, formId, isDeleted}) => {
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const [deleted, setDeleted] = useState(isDeleted);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    setDeleteTrigger(true);
  }
  
  useEffect(() => {
    if (!deleteTrigger) return () => { };
    Data.getInstance().markFormDeleted({accPath: `Accounts/${accountId}`, formId: formId} ).then(() => {
      setDeleteTrigger(false)
      setDeleted(true)
    }).catch((error) => {
      console.error("Couldn't delete form", error)
      setDeleteTrigger(false)
    });
  }, [deleteTrigger]);

  return (
    <Box sx={{padding:'0px'}}>
      {deleteTrigger && <Typography variant='inherit'>Deleting...</Typography>}
      {deleted && <Typography variant='inherit' color='error'>Deleted!</Typography>}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
          {!deleted && <MenuItem key={"delete"} onClick={handleDelete}>
            Delete
          </MenuItem>}
      </Menu>
    </Box>
  );
});