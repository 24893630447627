import { Box, Typography, IconButton, Button } from "@mui/material";
import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const RuleRow = React.memo(({rule, onEditClick, onDeleteClick}) => {
  const [deleting, setDeleting] = useState(false);

  return <Box sx={{display: 'flex', alignItems:'center', gap: '4px',}}>
    <Box sx={{display: 'flex', flexWrap:'wrap', alignItems:'center', gap: '12px', background:'#eee', px:'8px', py:'4px', borderRadius:'4px'}}>
      <Typography variant="subtitle2" sx={{ color:'#999', px:'10px' }}>Show</Typography>
      <Typography variant="subtitle2" sx={{ px:'20px', fontFamily:'monospace', border:'1px solid #3B7080', color:'#3B7080', borderRadius:'3px' }}>{rule.target.fieldId}</Typography>
      {rule.conditions && (rule.conditions.length>0) && rule.conditions.map(condition => <Box key={condition.id} sx={{display: 'flex', flexWrap:'wrap', alignItems:'center', gap: '12px',}}>
        <Typography variant="subtitle2" sx={{ color:'#999', px:'10px' }}>when</Typography>
        <Typography variant="subtitle2" sx={{ px:'20px', fontFamily:'monospace', border:'1px solid #3B7080', color:'#3B7080', borderRadius:'3px' }}>{condition.fieldId}</Typography>
        <Typography variant="subtitle2" sx={{ color:'#999', px:'10px' }}>is/contains</Typography>
        <Typography variant="subtitle2" sx={{ px:'20px', fontFamily:'monospace', background:'#fff', color:'#3B7080', borderRadius:'3px' }}>{condition.value}</Typography>
      </Box>)}
    </Box>
    <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: '4px',}}>
      <IconButton size='small' onClick={() => onEditClick(rule)}><EditIcon sx={{fontSize:'20px'}} /></IconButton>
      {!deleting && <IconButton size='small' onClick={() => setDeleting(true)} ><DeleteOutlineOutlinedIcon sx={{fontSize:'20px'}} /> </IconButton>}
      {deleting && <Button size="small" onClick={() => onDeleteClick(rule)} sx={{color:'#FF5714'}} >Yes, delete</Button>}
      {deleting && <Button size="small" onClick={() => setDeleting(false)} >Cancel</Button>}
    </Box>
  </Box>
})