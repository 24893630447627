import React from "react";
import { UI_TYPE } from "../../UiTypes";
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import ListIcon from '@mui/icons-material/List';
import TitleIcon from '@mui/icons-material/Title';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import HtmlOutlinedIcon from '@mui/icons-material/HtmlOutlined';
import AbcIcon from '@mui/icons-material/Abc';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';

export const isValidIdString = (name) => {
  // Regular expression for a valid JavaScript identifier
  const identifierRegex = /^[A-Za-z_$][A-Za-z0-9_$]*$/;

  // List of JavaScript reserved keywords
  const reservedKeywords = new Set([
    'abstract', 'await', 'boolean', 'break', 'byte', 'case', 'catch', 'char',
    'class', 'const', 'continue', 'debugger', 'default', 'delete', 'do', 'double',
    'else', 'enum', 'export', 'extends', 'false', 'final', 'finally', 'float',
    'for', 'function', 'goto', 'if', 'implements', 'import', 'in', 'instanceof',
    'int', 'interface', 'let', 'long', 'native', 'new', 'null', 'package',
    'private', 'protected', 'public', 'return', 'short', 'static', 'super',
    'switch', 'synchronized', 'this', 'throw', 'throws', 'transient', 'true',
    'try', 'typeof', 'var', 'void', 'volatile', 'while', 'with', 'yield'
  ]);

  // Check if the name matches the identifier regex and is not a reserved keyword
  return identifierRegex.test(name) && !reservedKeywords.has(name);
}

export const mapUiTypeToName = (uiType) => {
  switch (uiType) {
    case UI_TYPE.BULLETED_LIST: return 'Bulleted List'
    case UI_TYPE.BUTTON_SELECT: return 'Button Group'
    case UI_TYPE.CARD_GRID: return 'Info Cards'
    case UI_TYPE.CHECKBOXES: return 'Checkboxes'
    case UI_TYPE.DROPDOWN: return 'Dropdown'
    case UI_TYPE.EMAIL_HTML: return 'Email (HTML)'
    case UI_TYPE.FILE_LINK: return 'Link to File'
    case UI_TYPE.FILE_LINKS: return 'Links to Files'
    case UI_TYPE.HEADER: return 'Header Label'
    case UI_TYPE.IMAGE_GRID: return 'Images'
    case UI_TYPE.LABEL: return 'Label'
    case UI_TYPE.MARKDOWN: return 'Markdown'
    case UI_TYPE.RATING: return 'Rating'
    case UI_TYPE.STATIC_HEADER: return 'Static Header'
    case UI_TYPE.STATIC_LABEL: return 'Label'
    case UI_TYPE.STATIC_TEXT: return 'Static Text'
    case UI_TYPE.TEXT: return 'Text (Long)'
    case UI_TYPE.TEXT_INPUT: return 'Text Area'
    case UI_TYPE.TEXT_INPUT_SHORT: return 'Text Input'
    case UI_TYPE.TEXT_SHORT: return 'Text (Short)'
    case UI_TYPE.URL_LINK: return 'Link'
    case UI_TYPE.URL_LINKS: return 'Links'
  }
}

export const mapUiTypeToIcon = (uiType) => {
  switch (uiType) {
    case UI_TYPE.BULLETED_LIST: return <ListIcon />
    case UI_TYPE.BUTTON_SELECT: return <SmartButtonIcon />
    case UI_TYPE.CARD_GRID: return <WysiwygIcon />
    case UI_TYPE.CHECKBOXES: return <CheckBoxOutlinedIcon />
    case UI_TYPE.DROPDOWN: return <ArrowDropDownCircleOutlinedIcon />
    case UI_TYPE.EMAIL_HTML: return <HtmlOutlinedIcon />
    case UI_TYPE.FILE_LINK:
    case UI_TYPE.FILE_LINKS: return <CloudDownloadOutlinedIcon />
    case UI_TYPE.IMAGE_GRID: return <PhotoOutlinedIcon />
    case UI_TYPE.HEADER:
    case UI_TYPE.STATIC_TEXT:
    case UI_TYPE.STATIC_HEADER:
    case UI_TYPE.LABEL:
    case UI_TYPE.STATIC_LABEL: return <TitleIcon />
    case UI_TYPE.MARKDOWN: return <TagOutlinedIcon />
    case UI_TYPE.RATING: return <GradeOutlinedIcon />
    case UI_TYPE.TEXT: return <NotesOutlinedIcon />
    case UI_TYPE.TEXT_INPUT: return <EditNoteOutlinedIcon />
    case UI_TYPE.TEXT_INPUT_SHORT: return <BorderColorOutlinedIcon />
    case UI_TYPE.TEXT_SHORT: return <AbcIcon />
    case UI_TYPE.URL_LINK:
    case UI_TYPE.URL_LINKS: return <InsertLinkOutlinedIcon />
  }
}