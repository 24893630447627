import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, ListItemButton, Divider, Tooltip } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Data from './Data';
import Dots from './Dots';
import RunDate from './RunDate';
import { RUN_STATE_GROUP } from './RunStates';

const FormListItem = ({form, inclDivider, isCloningDocId, onItemClick, onCloneClick}) => {

  return (<>
  {inclDivider && <Divider component="li" />}
  <ListItem className="listItemParent" disablePadding secondaryAction={
    <Tooltip title={"Create a copy"}><IconButton disabled={isCloningDocId} onClick={() => onCloneClick(form)}>{isCloningDocId === form.docId ? <CircularProgress size={24} /> : <ContentCopyOutlinedIcon sx={{transform: 'scale(0.8)', color:'#ccc', '@media (hover: hover)': {'.listItemParent:hover &':{ color: '#555' }}, '@media (hover: none)': {color: '#555'}}} />}</IconButton></Tooltip>
  }>
    <ListItemButton onClick={() => onItemClick(form)} sx={{pl:'32px', py:'16px'}}>
      <ListItemAvatar>
        <Avatar sx={{bgcolor:'#CFDBD5'}}>{form.name.charAt(0)}</Avatar>
      </ListItemAvatar>
      <ListItemText sx={{paddingRight:'20px'}}
            primary={<Box sx={{display:'flex',flexDirection: {xs:'column', sm:'row'}, alignItems:'start', gap:{xs:'4px', sm:'20px'}, textWrap:'nowrap', overflow:'hidden'}}>{form.name}</Box>}
            
          />
    </ListItemButton>
  </ListItem>
  </>)
};

export default React.memo(FormListItem);