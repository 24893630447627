import { Box, TextField, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import React, { useState, useEffect } from "react"
import { UI_TYPE } from "../../UiTypes";
import { handleDoneKeyPress } from "../../utils/ui-utils";
import { isValidIdString } from "./field-utils"
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import EditIcon from '@mui/icons-material/Edit';

export const EditRating = ({compToEdit = null, formFieldOrder, onIsValidChange, onValueChange}) => {
  const [id, setId] = useState((compToEdit?.key != null) ? compToEdit?.key : null)
  const [required, setRequired] = useState((compToEdit?.object?.required != null) ? compToEdit?.object?.required : true)
  const [editable, setEditable] = useState((compToEdit?.object?.settings?.editable != null) ? compToEdit?.object?.settings?.editable : false)
  const [max, setMax] = useState((compToEdit?.object?.settings?.max != null) ? compToEdit?.object?.settings?.max : 5)
  const invalidId = id && (id.length > 0) && !isValidIdString(id)

  useEffect(() => {
    onValueChange({key: id, object: {uiType: UI_TYPE.RATING, required: required, settings:{max: max, editable: editable}}})
    onIsValidChange(id && (id.length > 0) && !invalidId && (compToEdit || !formFieldOrder || formFieldOrder.length == 0 || !formFieldOrder.includes(id)) && max && max>0 && max <=20)
  }, [id, required, editable, max]);

  return <Box sx={{display:'flex', flexDirection:'column', gap:'14px'}}>
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <Box sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><FingerprintIcon fontSize='small' />ID for element*</Box>
    {!compToEdit && <TextField id="input-id" variant="standard" error={invalidId} helperText={invalidId ? "Invalid characters" : null} sx={{fontSize:'14px'}} value={id || ""} onChange={(ev) => setId(ev.target.value)} inputProps={{ enterKeyHint: 'done' }} onKeyDown={handleDoneKeyPress} />}
    {compToEdit && <Typography sx={{my:'8px', fontFamily: 'Monospace'}}>{id || ""}</Typography>}
  </Box>
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
  <FormControl>
    <FormLabel sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><LocalPoliceIcon fontSize='small' />Selection required*</FormLabel>
    <RadioGroup
      row
      name="required-radio-buttons-group"
      value={required ? "yes" : "no"}
      onChange={(ev) => setRequired(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
      <FormControlLabel value="no" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>
  </Box>
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
  <FormControl>
    <FormLabel sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><EditIcon fontSize='small' />Allow user to change value*</FormLabel>
    <RadioGroup
      row
      name="editable-radio-buttons-group"
      value={editable ? "yes" : "no"}
      onChange={(ev) => setEditable(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
      <FormControlLabel value="no" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>
  </Box>
  <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <TextField id="input-max" variant="standard" label="Max stars*" sx={{fontSize:'14px'}} value={max} onChange={(ev) => setMax(isNaN(parseInt(ev.target.value)) ? null : parseInt(ev.target.value))} inputProps={{ enterKeyHint: 'done' }} onKeyDown={handleDoneKeyPress} />
  </Box>
</Box>
}