import { Dialog, DialogTitle, DialogContent, Typography, Box, FormControl, Select, MenuItem, TextField, DialogActions, Button } from "@mui/material"
import React, { useState, useEffect } from "react"
import { UI_TYPE } from "../../UiTypes";
import { handleDoneKeyPress } from "../../utils/ui-utils";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const AddRuleDialog = React.memo(({openRuleAdd, onClose, ruleToEdit, existingRuleTargetIds, formFields, onConfirm}) => {
  const target = ruleToEdit?.target
  const availableTargetIds = formFields ? Object.keys(formFields).filter(fieldId => (target?.fieldId === fieldId) || !(existingRuleTargetIds.includes(fieldId))) : null
  const [targetFieldId, setTargetFieldId] = useState(target?.fieldId)
  const attr = target?.attr || "show"
  const cond = (ruleToEdit?.conditions && ruleToEdit.conditions.length) ? ruleToEdit.conditions[0] : null
  const condId = cond?.id
  const [condFieldId, setCondFieldId] = useState(cond?.fieldId)
  const condOp = cond?.op || "is"
  const [condValue, setCondValue] = useState(cond?.value)
  const allGood = targetFieldId && attr && condFieldId && condOp && condValue && (condValue.length>0)
  const optionSelectIds = formFields ? Object.keys(formFields).filter(fieldId => (!targetFieldId || fieldId !== targetFieldId) && [UI_TYPE.BUTTON_SELECT, UI_TYPE.CHECKBOXES, UI_TYPE.DROPDOWN].includes(formFields[fieldId].uiType)) : null

  useEffect(() => {
    setTargetFieldId(target?.fieldId)
    setCondValue(cond?.value)
    setCondFieldId(cond?.fieldId)
  }, [target, cond])

  useEffect(() => {
    setCondFieldId(prev => (prev === targetFieldId) ? null : prev)
  }, [targetFieldId])

  const onConfirmed = () => {
    if (!allGood) return;
    const newRule = {
      id: ruleToEdit?.id || Date.now()+"",
      target: {
        fieldId: targetFieldId,
        attr: attr,
      },
      conditions: [
        {
          id: condId || Date.now()+"",
          fieldId: condFieldId,
          op: condOp,
          value: condValue
        }
      ]
    }
    onConfirm(newRule);
    onClose();
  }

  return <Dialog open={openRuleAdd} onClose={onClose}>
        <DialogTitle>{ruleToEdit ? 'Update' : 'Add'} rule</DialogTitle>
        <DialogContent>
          {(!availableTargetIds || !availableTargetIds.length) && <Typography variant='inherit' color='secondary' sx={{px:'0px'}}>All fields already have rules set.</Typography>}
          {(!optionSelectIds || !optionSelectIds.length) && <Typography variant='inherit' color='secondary' sx={{px:'0px'}}>Conditional display rules can currently only depend on selections of <u>other</u> {UI_TYPE.BUTTON_SELECT}, {UI_TYPE.CHECKBOXES} or {UI_TYPE.DROPDOWN}. But there are none.</Typography>}
          {availableTargetIds && (availableTargetIds.length > 0) && optionSelectIds && (optionSelectIds.length > 0) && <Box sx={{display:'flex', flexDirection:'column', gap:'6px'}}>
            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', gap:'6px'}}>
              <Typography variant='inherit' color='secondary' sx={{px:'0px'}}>Show</Typography>
              <FormControl sx={{ m: 1, minWidth: 180 }}>
                <Select
                  id="target-id-select"
                  size='small'
                  value={targetFieldId || ""}
                  onChange={(event) => setTargetFieldId(event.target.value)}
                  autoWidth
                >
                  {/* <MenuItem value=""><em>Pick component</em></MenuItem> */}
                  {availableTargetIds.map(fieldId => 
                    <MenuItem key={fieldId} value={fieldId}>{fieldId}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{display:'flex', flexDirection:'row', flexWrap:'wrap', alignItems:'center', gap:'6px'}}>
              <Typography variant='inherit' color='secondary' sx={{px:'0px'}}>When</Typography>
              <FormControl sx={{ m: 1, minWidth: 180 }}>
                <Select
                  id="cond-fieldid-select"
                  size='small'
                  value={condFieldId || ""}
                  onChange={(event) => setCondFieldId(event.target.value.length ? event.target.value : null)}
                  autoWidth
                >
                  {/* <MenuItem value=""><em>Pick component</em></MenuItem> */}
                  {optionSelectIds.map(fieldId => 
                    <MenuItem key={fieldId} value={fieldId}>{fieldId}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{display:'flex', flexDirection:'row', flexWrap:'wrap', alignItems:'center', gap:'6px'}}>
              <Typography variant='inherit' color='secondary' sx={{px:'0px'}}>is/contains</Typography>
              <TextField id="input-condValue" variant="standard" label="Option ID" sx={{fontSize:'14px'}} value={condValue || ""} onChange={(ev) => setCondValue(ev.target.value)} onKeyDown={handleDoneKeyPress} />
            </Box>
            <Typography variant='caption' sx={{px:'0px'}}>Hidden otherwise</Typography>
          </Box>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Dismiss</Button>
          <Button
          variant='contained'
          disabled={!allGood} 
          onClick={() => onConfirmed()}
          startIcon={<AddCircleOutlineIcon/>}
          >{ruleToEdit ? 'Update' : 'Save'} Rule</Button>
        </DialogActions>
      </Dialog>
})