import { Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Typography } from "@mui/material"
import React, { useState, useEffect } from "react"
import { isValidIdString } from "./field-utils"
import TitleIcon from '@mui/icons-material/Title';
import AbcIcon from '@mui/icons-material/Abc';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { UI_TYPE } from "../../UiTypes";

export const EditLabel = ({compToEdit = null, onIsValidChange, onValueChange}) => {
  const [fixedText, setFixedText] = useState(!compToEdit || compToEdit.object?.value != null)
  const [text, setText] = useState(compToEdit?.object?.value)
  const [id, setId] = useState((compToEdit?.key != null) ? compToEdit?.key : null)
  const [textSize, setTextSize] = useState(compToEdit?.object?.settings?.textSize || "m")
  const invalidId = id && id.length > 0 && !isValidIdString(id)
  useEffect(() => {
    onValueChange({key: fixedText ? (compToEdit?.key || Date.now()+"") : id, object: {uiType: fixedText ? UI_TYPE.STATIC_LABEL : UI_TYPE.LABEL, ...(fixedText && {value: text?.trim()}), settings:{textSize: textSize}}})
    onIsValidChange((fixedText || (id && id.length > 0 && !invalidId)) && (!fixedText || (text && (text.trim().length > 0))))
  }, [fixedText, id, text, textSize]);

  return <Box sx={{display:'flex', flexDirection:'column', gap:'14px'}}>
    {!compToEdit && <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
      <FormControl>
        <FormLabel sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><TitleIcon fontSize='small' />Text input*</FormLabel>
        <RadioGroup
          row
          name="fixedText-radio-buttons-group"
          value={fixedText ? "yes" : "no"}
          onChange={(ev) => setFixedText(ev.target.value === "yes")}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Static text" />
          <FormControlLabel value="no" control={<Radio />} label="Send with review request" />
        </RadioGroup>
      </FormControl>
    </Box>}
    {fixedText && <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px', width:'360px', maxWidth:'100%'}}>
      <Box sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><AbcIcon fontSize='small' />Label to show</Box>
      <TextField id="input-static-text" variant="standard" label="Enter text" multiline maxRows={8} fullWidth sx={{fontSize:'14px'}} value={text || ""} onChange={(ev) => setText(ev.target.value)} />
    </Box>}
    {!fixedText && <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
      <Box sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><FingerprintIcon fontSize='small' />ID for element*</Box>
      {!compToEdit && <TextField id="input-id" variant="standard" error={invalidId} helperText={invalidId ? "Invalid characters" : null} sx={{fontSize:'14px'}} value={id || ""} onChange={(ev) => setId(ev.target.value)} />}
      {compToEdit && <Typography sx={{my:'8px', fontFamily: 'Monospace'}}>{id || ""}</Typography>}
    </Box>}
    <Box sx={{px:'8px', py:'6px', background: '#f1f1f1', borderRadius:'6px'}}>
    <FormControl>
      <FormLabel sx={{display:'flex', alignItems:'center', gap:'8px', mb:'6px', fontSize:'14px', fontWeight:'500', color:'#777'}}><TextFieldsIcon fontSize='small' />Text size</FormLabel>
      <RadioGroup
        row
        name="textSize-radio-buttons-group"
        value={textSize}
        onChange={(ev) => setTextSize(ev.target.value)}
      >
        <FormControlLabel value="s" control={<Radio />} label="S" />
        <FormControlLabel value="m" control={<Radio />} label="M" />
        <FormControlLabel value="l" control={<Radio />} label="L" />
        <FormControlLabel value="xl" control={<Radio />} label="XL" />
      </RadioGroup>
    </FormControl>
    </Box>
  </Box>
}