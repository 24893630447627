import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, ListItemButton, Divider } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Data from './Data';
import Dots from './Dots';
import RunDate from './RunDate';
import { RUN_STATE, RUN_STATE_GROUP } from './RunStates';

const RunningWorkflow = ({run, inclDivider, timePing}) => {

  return (<>
  {inclDivider && <Divider component="li" />}
  <ListItem sx={{px:'32px'}}>
    <ListItemAvatar>
      <Avatar sx={{bgcolor:'#CFDBD5'}}>{run.workflowName.charAt(0)}</Avatar>
    </ListItemAvatar>
    <ListItemText sx={{paddingRight:'16px'}} primary={
      <Box sx={{display:'flex',flexDirection: {xs:'column', sm:'row'}, alignItems:'start', gap:{xs:'4px', sm:'20px'}, textWrap:'nowrap', overflow:'hidden', color:'#888'}}>{run.workflowName} - {run.taskName}{(run.state === RUN_STATE.TASK_RUNNING) && <Dots />}</Box>
    }/>
        
    {/* <RunDate timeStampDate={run.createdAt} pingForUpdate={timePing} /> */}
    
  </ListItem>
  </>)
};

export default React.memo(RunningWorkflow);