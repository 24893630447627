import { Dialog, Box, Typography, DialogContent, DialogActions, Button } from "@mui/material"
import { mapUiTypeToIcon, mapUiTypeToName } from "../formFields/field-utils"
import { EditIdOnlyType } from "../formFields/IdField"
import { EditLabel } from "../formFields/LabelField"
import { EditOptionsSelect } from "../formFields/OptionsField"
import { EditRating } from "../formFields/RatingField"
import { EditStaticText } from "../formFields/StaticTextField"
import { EditText } from "../formFields/TextField"
import DoneIcon from '@mui/icons-material/Done';
import React, { useState } from "react"
import { UI_TYPE } from "../../UiTypes";

export const AddFieldDialog = ({openComponentAdd, onClose, uiType, compToEdit, formFieldOrder, onConfirm}) => {
  const [isValid, setIsValid] = useState(compToEdit != null)
  const [value, setValue] = useState(compToEdit)
  const allGood = !!value && isValid

  const onConfirmed = () => {
    if (!allGood) return;
    onConfirm(value);
    onClose();
  }

  const mapCompAdd = (uiType) => {
    switch (uiType) {
      case UI_TYPE.STATIC_TEXT:
      case UI_TYPE.STATIC_HEADER:
        return <EditStaticText uiType={uiType} compToEdit={compToEdit} onIsValidChange={setIsValid} onValueChange={setValue} />
      case UI_TYPE.BUTTON_SELECT:
      case UI_TYPE.CHECKBOXES:
      case UI_TYPE.DROPDOWN:
        return <EditOptionsSelect uiType={uiType} compToEdit={compToEdit} formFieldOrder={formFieldOrder} onIsValidChange={setIsValid} onValueChange={setValue} />
      case UI_TYPE.LABEL:
      case UI_TYPE.STATIC_LABEL:
        return <EditLabel compToEdit={compToEdit} onIsValidChange={setIsValid} onValueChange={setValue} />
      case UI_TYPE.TEXT:
      case UI_TYPE.TEXT_SHORT:
      case UI_TYPE.TEXT_INPUT:
      case UI_TYPE.TEXT_INPUT_SHORT:
      case UI_TYPE.MARKDOWN:
      case UI_TYPE.EMAIL_HTML:
        return <EditText uiType={uiType} compToEdit={compToEdit} formFieldOrder={formFieldOrder} onIsValidChange={setIsValid} onValueChange={setValue} editableIsMandatory={[UI_TYPE.TEXT_INPUT, UI_TYPE.TEXT_INPUT_SHORT].includes(uiType)} />
      case UI_TYPE.RATING:
        return <EditRating compToEdit={compToEdit} formFieldOrder={formFieldOrder} onIsValidChange={setIsValid} onValueChange={setValue} />
      case UI_TYPE.BULLETED_LIST:
      case UI_TYPE.FILE_LINK:
      case UI_TYPE.FILE_LINKS:
      case UI_TYPE.URL_LINK:
      case UI_TYPE.URL_LINKS:
      case UI_TYPE.CARD_GRID:
      case UI_TYPE.IMAGE_GRID:
      case UI_TYPE.HEADER:
        return <EditIdOnlyType uiType={uiType} compToEdit={compToEdit} formFieldOrder={formFieldOrder} onIsValidChange={setIsValid} onValueChange={setValue} />
      default:
        return "";
    }
  }
  return <Dialog open={openComponentAdd} onClose={onClose}>
    <Box sx={{display:'flex', alignItems:'center', px:'24px', py:'16px', gap: '12px', color:'#444'}}>{mapUiTypeToIcon(uiType)}<Typography sx={{fontSize:'20px', fontWeight:'600', color:'inherit', }}>{mapUiTypeToName(uiType)}</Typography></Box>
        <DialogContent>
          {mapCompAdd(uiType)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Dismiss</Button>
          <Button 
          variant='contained'
          disabled={!allGood} 
          onClick={() => onConfirmed()}
          startIcon={<DoneIcon />}
          >{compToEdit ? 'Update' : 'Add'} Field</Button>
        </DialogActions>
      </Dialog>
}