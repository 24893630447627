import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import {Outlet, useParams, useNavigate, NavLink} from 'react-router-dom';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, Box, TextField, Rating, Link, Paper, ToggleButtonGroup, ToggleButton, Button, IconButton, Tooltip, FormControl, FormGroup, FormControlLabel, Checkbox, Select, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import Markdown from 'react-markdown'
import { Letter } from 'react-letter';
import Data from './Data';
import { AccountContext, UserContext } from './AccountFrame';
import { REVIEW_STATE } from './ReviewStates';
import { UI_TYPE } from './UiTypes';
import FirebaseManager from './FirebaseManager';

const ReviewForm = ({user, review, previewOnly=false, fromPublicLink=false, onResponseValuesChanged}) => {
  // const accountCtx = useContext(AccountContext);
  // const accountId = accountCtx?.account?.id
  const { accountId } = useParams();
  const formRules = review?.form?.rules
  const [formItems, setFormItems] = useState([]);
  const [formItemsToShow, setFormItemsToShow] = useState([]);
  const [formInputs, setFormInputs] = useState();
  const [responseValues, setResponseValues] = useState({});
  const [submitTrigger, setSubmitTrigger] = useState(false);
  const [allFieldResponsesValid, setAllFieldResponsesValid] = useState(false);
  const alreadyResponded = review && (review.state == REVIEW_STATE.RESPONDED);

  useEffect(() => {  
    const checkRequirements = (formItems, responseValues) => {
      for (let formField of formItems) {
        const key = formField.field;
        const hasResponse = (key in responseValues) && responseValues[key] != null && responseValues[key].value != null
        const responseValue = hasResponse ? responseValues[key].value : null

        if ([UI_TYPE.BUTTON_SELECT, UI_TYPE.CHECKBOXES, UI_TYPE.DROPDOWN].includes(formField.uiType)){
          // CHECK REQUIRED && MIN LENGTH
          if (formField.required && (!hasResponse || (Array.isArray(responseValue) && (responseValue.length < (formField.settings?.minSelect || 1))) || ((typeof responseValue === 'string') && responseValue.length == 0))) { //empty string probably cant happen
            return false;
          }
          // CHECK MAX LENGTH
          if (hasResponse && Array.isArray(responseValue) && (formField.settings?.maxSelect != null) && (responseValue.length > formField.settings?.maxSelect)) {
            return false;
          }
        } else if ((([UI_TYPE.TEXT, UI_TYPE.TEXT_SHORT, UI_TYPE.MARKDOWN, UI_TYPE.EMAIL_HTML].includes(formField.uiType)) && formField.settings?.editable) || [UI_TYPE.TEXT_INPUT, UI_TYPE.TEXT_INPUT_SHORT].includes(formField.uiType)) {
          // CHECK MIN LENGTH
          if (formField.settings?.minLength && (!hasResponse || responseValue.length < formField.settings.minLength))
            return false;
          // CHECK MAX LENGTH
          if (formField.settings?.maxLength && hasResponse && responseValue.length > formField.settings.maxLength)
            return false;          
        } else if (formField.uiType === UI_TYPE.RATING && formField.required && !hasResponse) {
          // CHECK REQUIRED
          return false;
        }
        /* if (!(key in values) || values[key] == null || values[key].value == null)
          console.log("novalue " + key)
        else
          console.log(`field ${key}: length: ${Array.isArray(values[key].value) && values[key].value.length} nReq: ${formField?.settings?.minSelect || 1} minFail: ${Array.isArray(values[key].value) && (values[key].value.length < (formField?.settings?.minSelect || 1))} maxFail: ${Array.isArray(values[key].value) && ((formField?.settings?.maxSelect != null) && (values[key].value.length > formField?.settings?.maxSelect))} reject: ${(Array.isArray(values[key].value) && (values[key].value.length < (formField?.settings?.minSelect || 1) || ((formField?.settings?.maxSelect != null) && (values[key].value.length > formField?.settings?.maxSelect))))}`) */
        
      }
      return true;
    }
    setAllFieldResponsesValid(checkRequirements(formItemsToShow || [], responseValues))
    
  }, [formItemsToShow, responseValues]);

  useEffect(() => {
    if (previewOnly || !accountId || (!fromPublicLink && !user) || !submitTrigger || alreadyResponded) return () => { };
    const millisSinceRequest =  (new Date()).getTime() - (review.createdAt?.toMillis() || 0)
    const subscription = Data.getInstance().sendFormSubmit(user?.id, user?.email, accountId, review.docId, fromPublicLink, responseValues, millisSinceRequest)
      .subscribe((x) => {
        setSubmitTrigger(false);
        FirebaseManager.trackEvent("SubmitReviewForm")
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [submitTrigger]);
    
  useEffect(() => {
    if (onResponseValuesChanged) {
      onResponseValuesChanged(responseValues)
    }
  }, [responseValues]);
    
  useEffect(() => {
    console.log("setFormItems", review)

    const getOrderedFieldsWithValues = (review) => {
      const { form, fields } = review;
      const { fieldOrder, fields: formFields } = form;
    
      return fieldOrder.map(fieldKey => {
        const field = { ...formFields[fieldKey] };
        if (fields && fields.hasOwnProperty(fieldKey))
          field.requestValues = fields[fieldKey];
        field.field = fieldKey;
        return field;
      });
    }

    setFormItems((review && review.form && review.form.fields) ? getOrderedFieldsWithValues(review) : [])
  }, [review]);

  useEffect(() => {
    if (!formItems || !formItems.length) return () => { };
    
    const defaultFormInputs = {}
    for (let formItem of formItems) {
      if ((formItem.requestValues != null) && [UI_TYPE.TEXT, UI_TYPE.TEXT_SHORT, UI_TYPE.TEXT_INPUT, UI_TYPE.TEXT_INPUT_SHORT, UI_TYPE.MARKDOWN, UI_TYPE.EMAIL_HTML, UI_TYPE.RATING].includes(formItem.uiType))
        defaultFormInputs[formItem.field] = formItem.requestValues
    }
    console.log("defaultFormInputs", defaultFormInputs)
    setFormInputs(prev => {return {...defaultFormInputs, ...prev}}) // previous input overwrites here (but input for deleted items remains?!)
  }, [formItems]);
    
  useEffect(() => {
    console.log("formInputs", JSON.stringify(formInputs))
    console.log("formItems", JSON.stringify(formItems))
    console.log("formRules", JSON.stringify(formRules))

    function getShownFormFields(formItems, formInputs, rules) {
      const fieldVisibilityRules = new Map();
      
      rules.forEach(rule => {
        fieldVisibilityRules.set(rule.target.fieldId, rule);
      });
    
      return formItems.filter(item => {
        const rule = fieldVisibilityRules.get(item.field);
        
        if (!rule) {
          return true;
        }
    
        const logicType = rule.logic || 'AND';
        
        if (logicType === 'AND') {
          return rule.conditions.every(condition => {
            const inputValue = formInputs[condition.fieldId];
            if (inputValue === undefined) return false;
            // future: could check for condition.op === 'is' if there are other op
            if (Array.isArray(inputValue)) {
              return inputValue.includes(condition.value);
            } else {
              return inputValue === condition.value;
            }
          });
        } else if (logicType === 'OR') {
          return rule.conditions.some(condition => {
            const inputValue = formInputs[condition.fieldId];
            if (inputValue === undefined) return false;
            // future: could check for condition.op === 'is' if there are other op
            if (Array.isArray(inputValue)) {
              return inputValue.includes(condition.value);
            } else {
              return inputValue === condition.value;
            }
          });
        }
        return true;
      });
    }
    
    const shownItems = getShownFormFields(formItems || [], formInputs || {}, formRules || [])
    setFormItemsToShow(shownItems)

    if (!formInputs) return () => { };

    const getTextResponseField = (formItem, newValue) => {
      return { value: newValue || "", ...(formItem.requestValues != null && {wasEdited: (formItem.requestValues !== newValue)}) };
    }
  
    const getRatingResponseField = (formItem, newValue) => {
      return { value: newValue, ...(formItem.requestValues != null && {wasEdited: (formItem.requestValues !== newValue)}) };
    }

    const newResponseValues = {}
    for (let formItem of shownItems) {
      const inputOrRequestValue = (formItem.field in formInputs) ? formInputs[formItem.field] : formItem.requestValues
      if (formItem.uiType === UI_TYPE.TEXT || formItem.uiType === UI_TYPE.TEXT_SHORT || formItem.uiType === UI_TYPE.TEXT_INPUT || formItem.uiType === UI_TYPE.TEXT_INPUT_SHORT || formItem.uiType === UI_TYPE.MARKDOWN || formItem.uiType === UI_TYPE.EMAIL_HTML) {
        newResponseValues[formItem.field] = getTextResponseField(formItem, inputOrRequestValue)
      } else if (formItem.uiType === UI_TYPE.RATING) {
        if (inputOrRequestValue) {
          newResponseValues[formItem.field] = getRatingResponseField(formItem, inputOrRequestValue)
        }
      } else if (formItem.uiType === UI_TYPE.CHECKBOXES || formItem.uiType === UI_TYPE.DROPDOWN || formItem.uiType === UI_TYPE.BUTTON_SELECT) {
        if (formInputs[formItem.field]) {
          newResponseValues[formItem.field] = {value: formInputs[formItem.field]}
        }
      } else if (formItem.requestValues != null) {
        newResponseValues[formItem.field] = { value: formItem.requestValues }
      }
    }
    console.log("newResponseValues", newResponseValues)
    setResponseValues(newResponseValues)
  }, [formItems, formInputs, formRules]);

  const onInputChanged = useCallback((formItem, newValue) => {
    console.log("onInputChanged " + formItem.field, newValue)
    setFormInputs(current => {return {...current, [formItem.field]: newValue}})
  },[])

  const mapUiTypes = (formItem) => {
    const currentValue = alreadyResponded ? review.responseValues[formItem.field]?.value : (formInputs ? formInputs[formItem.field] : null)
    // console.log("mapUiTypes currentValue", currentValue)
    const isTextInput = [UI_TYPE.TEXT_INPUT, UI_TYPE.TEXT_INPUT_SHORT].includes(formItem.uiType);
    switch (formItem.uiType) {
      case UI_TYPE.LABEL:
      case UI_TYPE.STATIC_LABEL:
      case UI_TYPE.STATIC_TEXT:
        return (formItem.value || formItem.requestValues) ? <LabelComponent formItem={formItem} /> : ""
      case UI_TYPE.STATIC_HEADER:
        return <HeaderComponent text={formItem.value} />
      case UI_TYPE.CHECKBOXES:
        return (formItem.values || formItem.requestValues) ? <Checkboxes formItem={formItem} disabled={submitTrigger || alreadyResponded} currentValue={currentValue} onChange={onInputChanged}  /> :""
      case UI_TYPE.DROPDOWN:
        return (formItem.values || formItem.requestValues) ? <DropDownSelect formItem={formItem} disabled={submitTrigger || alreadyResponded} currentValue={currentValue} onChange={onInputChanged}  /> : ""
      case UI_TYPE.BUTTON_SELECT:
        return (formItem.values || formItem.requestValues) ? <ButtonSelect formItem={formItem} disabled={submitTrigger || alreadyResponded} currentValue={currentValue} onChange={onInputChanged} /> : ""
      case UI_TYPE.HEADER:
        return formItem.requestValues ? <HeaderComponent text={formItem.requestValues} /> : ""
      case UI_TYPE.TEXT:
      case UI_TYPE.TEXT_SHORT:
      case UI_TYPE.TEXT_INPUT:
      case UI_TYPE.TEXT_INPUT_SHORT:
      case UI_TYPE.MARKDOWN:
      case UI_TYPE.EMAIL_HTML:
        return (formItem.requestValues != null || isTextInput) ? <TextComponent formItem={formItem} disabled={submitTrigger || alreadyResponded} alwaysInEditMode={isTextInput} currentValue={currentValue} onChange={onInputChanged} /> : ""
      case UI_TYPE.FILE_LINK:
        return formItem.requestValues ? <FileLink values={formItem.requestValues} /> : ""
      case UI_TYPE.URL_LINK:
        return formItem.requestValues ? <UrlLink values={formItem.requestValues} /> : ""
      case UI_TYPE.FILE_LINKS:
        return formItem.requestValues ? <FileLinks values={formItem.requestValues} /> : ""
      case UI_TYPE.URL_LINKS:
        return formItem.requestValues ? <UrlLinks values={formItem.requestValues} /> : ""
      case UI_TYPE.CARD_GRID:
        return formItem.requestValues ? <CardGrid values={formItem.requestValues} /> : ""
      case UI_TYPE.IMAGE_GRID:
        return formItem.requestValues ? <ImageGrid values={formItem.requestValues} /> : ""
      case UI_TYPE.RATING:
        console.log("ratingCurr ", currentValue)
        // for now we show it even if no value is passed because it is more likely to also be used as an input field?! Alternatively we must tell people to pass a 0 to show.
        return <RatingBar formItem={formItem} disabled={submitTrigger || alreadyResponded} currentValue={currentValue} onChange={onInputChanged} />
      case UI_TYPE.BULLETED_LIST:
        return formItem.requestValues ? <BulletedList values={formItem.requestValues} /> : ""
      default:
        return JSON.stringify(formItem)
    }
  }

  return (<Box sx={{display:'flex', flexDirection:'column'}}>
      <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', marginBottom:'20px'}}>
        <Box sx={{flexGrow: 1, paddingLeft:'20px', paddingRight:'20px', paddingTop:'0px'}}>
          <Typography variant="h4" sx={{ fontWeight: '600' }}>{review.form.name || ""}</Typography>
        </Box>
        {!previewOnly && !!accountId && !fromPublicLink && !!user && <DropDownMenu accountId={accountId} user={user} reviewId={review.docId} isDeleted={review.state === 'deleted'} />}
      </Box>
      <Box sx={{display:'flex', flexDirection:'column', marginBottom:'20px'}}>
        <Typography variant="subtitle2" sx={{color:'#888', fontSize:'12px', paddingLeft:'20px', textAlign:'start'}}>{(review.createdAt) ? ((new Date(review.createdAt.seconds * 1000)).toDateString() + " " + (new Date(review.createdAt.seconds * 1000)).toLocaleTimeString()) : ""}</Typography>
      </Box>
      <Box sx={{display:'flex', flexDirection:'column', gap:'20px', marginTop:'14px', marginBottom:'10px', px:'20px', py:'40px', border:'1px solid #ccc', borderRadius:'8px', background:'#E7E5DF'}}>
        {formItemsToShow.map((formItem) =>
          <Box key={formItem.field}>{mapUiTypes(formItem)}</Box>
        )}
        {alreadyResponded && <ReactedSticker review={review} />}
          <Button variant="contained" color="approve" sx={{alignSelf:'end', borderRadius: '8px'}} disabled={alreadyResponded || !allFieldResponsesValid || submitTrigger} onClick={()=>{if (!previewOnly) setSubmitTrigger(true);}} startIcon={(submitTrigger && !alreadyResponded) ? <CircularProgress color="approve" size={24} /> : <DoneIcon />}>Submit</Button>
      </Box>
    </Box>
  )
};

export default React.memo(ReviewForm);

const _HeaderComponent = ({text}) => {
  return <Typography sx={{whiteSpace:'pre-line'}} variant='h6'>{text}</Typography>
}
const HeaderComponent = React.memo(_HeaderComponent)

const _LabelComponent = ({formItem}) => {
  const sizeMap = {
    's': 'caption',
    'm': 'body1',
    'l': 'h6',
    'xl': 'h4',
  };
  const size = sizeMap[formItem.settings?.textSize || 'm'];
  return <Typography variant={size} sx={{whiteSpace:'pre-line'}}>{formItem.requestValues || formItem.value}</Typography>
}
const LabelComponent = React.memo(_LabelComponent)

const TextComponent = ({formItem, currentValue, onChange, disabled, alwaysInEditMode = false}) => {
  const [editing, setEditing] = useState(alwaysInEditMode);
  const [expandable, setExpandable] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const autoExpandHeight = formItem.settings?.autoExpandHeight
  const textElementRef = useRef(null);
  
  const isMarkdown = formItem.uiType === UI_TYPE.MARKDOWN;
  const isEmail = formItem.uiType === UI_TYPE.EMAIL_HTML;
  const isInput = formItem.uiType === UI_TYPE.TEXT_INPUT || formItem.uiType === UI_TYPE.TEXT_INPUT_SHORT;
  const isShortTextOrInput = formItem.uiType === UI_TYPE.TEXT_INPUT_SHORT || formItem.uiType === UI_TYPE.TEXT_SHORT;

  useEffect(() => {
    if (textElementRef.current && !isShortTextOrInput) {
      setExpandable((!(autoExpandHeight || false)) && (expanded || isScrollableY(textElementRef.current))); // when expanded stays expandable because we can only detect scrollable when collapsed
    }
  }, [editing, expanded, autoExpandHeight, isShortTextOrInput]);

  useEffect(() => {
    setExpanded(autoExpandHeight || false);
  }, [autoExpandHeight]);

  function canScroll(el, scrollAxis) {
    if (0 === el[scrollAxis]) {
        el[scrollAxis] = 1;
        if (1 === el[scrollAxis]) {
            el[scrollAxis] = 0;
            return true;
        }
    } else {
        return true;
    }
    return false;
}

function isScrollableY(el) {
    return (el.scrollHeight > el.clientHeight) && canScroll(el, 'scrollTop') && ('hidden' !== getComputedStyle(el).overflowY);
}

  if (currentValue && (typeof currentValue !== 'string')) return <ErrorValue />
  
  const failsMinLength = formItem.settings?.minLength && ((currentValue || "").length < formItem.settings.minLength)
  const failsMaxLength = formItem.settings?.maxLength && ((currentValue || "").length > formItem.settings.maxLength)
  return (
    <Box sx={{display:'flex', flexDirection:'column', gap: '8px', width:'100%'}}>
        {(formItem.label) && (
          <Box sx={{display:'flex', flexDirection:'row', gap: '8px'}}>
            <Box sx={{paddingLeft:'10px', typography: 'caption'}}>{formItem.label}</Box>
          </Box>)}
          <Box className="textBox" sx={{display:'flex', flexWrap: isShortTextOrInput ? 'nowrap' : 'wrap', alignItems:'center', position: 'relative', width:(!editing || disabled) ? 'fit-content' : (isShortTextOrInput ? '540px' : 'auto'), maxWidth: '100%', background:'#fff', borderRadius:'4px', gap: '0px'}}>
            {(currentValue != null) && (!editing || disabled) && <Box ref={textElementRef} sx={{
              width:'fit-content',
              maxHeight: expanded ? 'none' : '18em',
              maxWidth: '100%',
              overflowX: 'auto',
              overflowY: 'auto',
              padding: '4px',
              '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 8,},'::-webkit-scrollbar': {width: '6px', height: '6px'},
            }}>
              {!isMarkdown && !isEmail && <Typography sx={{padding:'10px', whiteSpace:'pre-line', textWrap: isShortTextOrInput ? 'nowrap' : 'wrap', lineHeight:'1.5em'}}>{(currentValue || <>&nbsp;</>)}</Typography>}
              {isMarkdown && <Box sx={{padding:'10px'}}><Markdown>{(currentValue || "")}</Markdown></Box>}
              {isEmail && <Box sx={{padding:'10px'}}><Letter html={(currentValue || "")} /></Box>}
            </Box>}
              {expandable && expanded && <Tooltip title="Collapse"><IconButton color="secondary" sx={{position: 'absolute', bottom: 16, right: 16, display: 'none', '@media (hover: hover)': {'.textBox:hover &':{ display: 'flex' }}, '@media (hover: none)': {display: 'flex'} }} onClick={() => setExpanded(false)}><UnfoldLessIcon /></IconButton></Tooltip>}
              {expandable && !expanded && <Tooltip title="Expand"><IconButton color="secondary" sx={{position: 'absolute', bottom: 16, right: 16, display: 'none', '@media (hover: hover)': {'.textBox:hover &':{ display: 'flex' }}, '@media (hover: none)': {display: 'flex'}}} onClick={() => setExpanded(true)}><UnfoldMoreIcon /></IconButton></Tooltip>}
            {editing && !disabled && <>
              {!isShortTextOrInput && <TextField
                fullWidth
                multiline
                error={!isInput && (failsMinLength || failsMaxLength)}
                maxRows={expanded ? null : 12}
                sx={{whiteSpace:'pre-line'}}
                value={currentValue || ""}
                onChange={(event) => {
                  onChange(formItem, event.target.value);
                }}
              />}
              {isShortTextOrInput && <TextField
                variant='standard'
                value={currentValue || ""}
                error={!isInput && (failsMinLength || failsMaxLength)}
                sx={{flexGrow: 1, p:'10px'}}
                onChange={(event) => {
                  onChange(formItem, event.target.value);
                }}
              />}
            </>}
            <Box sx={{display:'flex', flexDirection:{xs:'column', sm: 'row'}, alignItems:'center'}}>
            {formItem.settings?.editable && !disabled && !alwaysInEditMode && <Box sx={{display:'flex', width:'fit-content', px:'10px', py:'6px'}}>
              {!editing && <Button variant="outlined" color="primary" onClick={()=>setEditing(true)} startIcon={<EditIcon />}>Edit</Button>}
              {editing && <Button variant="contained" color="primary" onClick={()=>setEditing(false)} startIcon={<DoneIcon />}>Done</Button>}
            </Box>}
            {formItem.settings?.editable && ((editing && (failsMinLength || formItem.settings?.maxLength)) || !editing && (failsMinLength || failsMaxLength)) && <Typography variant='caption' sx={{px:'10px', py:'6px', textWrap:'nowrap', color:((failsMinLength || failsMaxLength) ? '#FF5714' : '#3B7080')}}>{(currentValue || "").length + (formItem.settings.maxLength ? (" / " + formItem.settings?.maxLength) : "") + (failsMinLength ? (` (min. ${formItem.settings?.minLength})`) : "")}</Typography>}
            </Box>
        </Box>
      </Box>
  )
}

const FileLink = React.memo(({values}) => {
  if (!values || ((typeof values) !== "object") || !values.url) return ""
  return <Box sx={{ display:'flex', width:'fit-content', maxWidth:'100%', alignItems:'center', gap:'6px', color: '#71717a', background:'#FFF', border:'1px solid #ccc', borderRadius:'8px', px:'12px', py:'4px' }}><CloudDownloadIcon fontSize='12px' /><Typography variant="subtitle2" sx={{textWrap:'nowrap', overflow:'hidden'}} component={Link} href={values.url} target="_blank">{values.label || values.url}</Typography></Box>
})

const UrlLink = React.memo(({values}) => {
  if (!values || ((typeof values) !== "object") || !values.url) return ""
  return <Box sx={{ display:'flex', width:'fit-content', maxWidth:'100%', alignItems:'center', gap:'6px', color: '#71717a', background:'#FFF', border:'1px solid #ccc', borderRadius:'8px', px:'12px', py:'4px' }}><OpenInNewIcon fontSize='12px' /><Typography variant="subtitle2" sx={{textWrap:'nowrap', overflow:'hidden'}} component={Link} href={values.url} target="_blank">{values.label || values.url}</Typography></Box>
})

const FileLinks = React.memo(({values}) => {
  if (!values || !Array.isArray(values)) return ""
  return (<Box sx={{display:'flex', flexWrap:'wrap', gap:'6px'}}>
    {values.map((val, i) =>
      <FileLink key={i} values={val} />
    )}
  </Box>)
})

const UrlLinks = React.memo(({values}) => {
  if (!values || !Array.isArray(values)) return ""
  return (<Box sx={{display:'flex', flexWrap:'wrap', gap:'6px'}}>
    {values.map((val, i) =>
      <UrlLink key={i} values={val} />
    )}
  </Box>)
})

const CardGrid = React.memo(({values}) => {
  if (!values || !Array.isArray(values)) return <ErrorValue />
  return (<Grid container spacing={4}>
    {values.map((card, i) =>
      <Grid key={i} xs={12} sm={12} md={6} lg={4}>
        {(!card || ((typeof card) !== "object") || (!card.header && !card.subtitle && (!card.stats || !Array.isArray(card.stats)))) ? <ErrorValue /> : (
        <Paper sx={{ padding: '20px', minHeight: '120px', border:'1px solid #ccc', borderRadius:'8px', overflowX: 'hidden' }}>
          {card.header && <Typography sx={{ marginBottom: '10px', fontSize:'18px', fontWeight: '500' }}>{card.header}</Typography>}
          {card.subtitle && <Typography variant='body2' sx={{ marginBottom: '22px', lineHeight: '18px' }}>{card.subtitle}</Typography>}
          <Box sx={{display: 'flex', flexDirection:'column', gap: '4px'}}>
            {card.stats && card.stats.map((stat,i) => <Box key={i} sx={{display: 'flex', gap: '10px'}}>
              <Typography variant='body2' sx={{ fontWeight: '600' }}>{stat.key}</Typography>
              <Typography variant='body2' sx={{  }}>{stat.value}</Typography>
            </Box>)}
          </Box>
        </Paper>)}
      </Grid>
    )}
  </Grid>)
})

const ImageGrid = React.memo(({values}) => {
  if (!values || !Array.isArray(values)) return <ErrorValue />
  return (<Grid container spacing={4}>
    {values.map((card, i) =>
      <Grid key={i} xs={12} sm={6} md={6} lg={4}>
        {(!card || ((typeof card) !== "object") || (!card.label && !card.url)) ? <ErrorValue /> : (
        <Paper sx={{ padding: '20px', minHeight: '120px', border:'1px solid #ccc', borderRadius:'8px', overflowX: 'hidden' }}>
          {card.label && <Typography variant='body2' sx={{ marginBottom: '22px', lineHeight: '18px' }}>{card.label}</Typography>}
          {card.url && <a href={card.url} target="_blank"><img
            src={`${card.url}`}
            width='100%'
            alt={card.label}
            loading="lazy"
          /></a>}
          {card.url && <Typography variant='caption' sx={{ color:'#aaa' }}>Click for full size</Typography>}
        </Paper>)}
      </Grid>
    )}
  </Grid>)
})

const RatingBar = React.memo(({formItem, currentValue, onChange, disabled}) => {
  return (
    <Rating name={formItem.field} value={currentValue || 0} disabled={disabled} readOnly={!formItem.settings.editable} precision={0.5} max={formItem.settings.max} onChange={(event, newValue) => { onChange(formItem, newValue); }} />
  )
})

const BulletedList = React.memo(({values}) => {
  if (!values || !Array.isArray(values)) return <ErrorValue />
  return (
    <List sx={{ listStyleType: 'disc', pl:4 }}>
      {values.map((val, i) => <ListItem key={i} sx={{ display: 'list-item' }}>{val}</ListItem>)}
    </List>
  )
})

const ButtonSelect = React.memo(({formItem, currentValue, onChange, disabled}) => {
  const isSmallScreenOrLess = useMediaQuery(useTheme().breakpoints.down("md"))
  const isXSmallScreenOrLess = useMediaQuery(useTheme().breakpoints.down("sm"))
  // if introducing default values, change useEffect[formItems]! NOTE: here `requestValues` are not API-preset inputs/selections but choices
  let choices
  if (formItem.values)
    choices = formItem.values
  else {
    if (formItem.requestValues) {
      if (!Array.isArray(formItem.requestValues) || formItem.requestValues.some(item => (!item.id || !item.label)))
        return <ErrorValue />
      else
        choices = formItem.requestValues
    } else return ""
  }

  return (
    <ToggleButtonGroup
      value={currentValue || ""}
      exclusive={!formItem.settings.multiSelect}
      onChange={(event, newValue) => onChange(formItem, newValue == null ? null : (Array.isArray(newValue) ? (newValue.length ? newValue : null) : newValue))}
      disabled={disabled}
      orientation={isSmallScreenOrLess ? ((isXSmallScreenOrLess && choices.length > 2) ? 'vertical' : 'horizontal') : 'horizontal'}
    >
      {/* requestValues if coming per API with review. values if statically set */}
      {choices.map(val => <ToggleButton key={val.id} color={(val.style && ['accept', 'reject'].includes(val.style)) ? val.style : 'secondary'} value={val.id}>{val.label}</ToggleButton>)}
    </ToggleButtonGroup>
  )
})

const DropDownSelect = React.memo(({formItem, currentValue, onChange, disabled}) => {
  // if introducing default values, change useEffect[formItems]! NOTE: here `requestValues` are not API-preset inputs/selections but choices
  
  let choices
  if (formItem.values)
    choices = formItem.values
  else {
    if (formItem.requestValues) {
      if (!Array.isArray(formItem.requestValues) || formItem.requestValues.some(item => (!item.id || !item.label)))
        return <ErrorValue />
      else
        choices = formItem.requestValues
    } else return ""
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 180 }} disabled={disabled}>
        <Select
          id="dropdown-select"
          value={currentValue || ""}
          onChange={(event) => onChange(formItem, event.target.value.length ? event.target.value : null)}
          autoWidth
        >
          <MenuItem value="">-</MenuItem>
          {choices.map(val => 
            <MenuItem key={val.id} value={val.id}>{val.label}</MenuItem>
          )}
        </Select>
      </FormControl>
  )
})

const Checkboxes = React.memo(({formItem, currentValue, onChange, disabled}) => {
  // if introducing default values, change useEffect[formItems]! NOTE: here `requestValues` are not API-preset inputs/selections but choices
  
  let choices
  if (formItem.values)
    choices = formItem.values
  else {
    if (formItem.requestValues) {
      if (!Array.isArray(formItem.requestValues) || formItem.requestValues.some(item => (!item.id || !item.label)))
        return <ErrorValue />
      else
        choices = formItem.requestValues
    } else return ""
  }

  const handleChange = (event) => {
    let changeArr = currentValue ? [...currentValue] : [];
    if (event.target.checked) {
      if (!changeArr.includes(event.target.name)) {
        changeArr.push(event.target.name);
      }
    } else {
      changeArr = changeArr.filter(val => val !== event.target.name);
    }
    onChange(formItem, changeArr.length ? changeArr : null);
  };

  return (
    <FormControl
        sx={{ m: 3 }}
        variant="standard"
        disabled={disabled}
      >
        <FormGroup>
        {choices.map(val => 
        {console.log("Checkboxes val " + (currentValue ? currentValue.includes(val.id) : "none"), val)
          return <FormControlLabel
            key={val.id}
            control={
              <Checkbox checked={currentValue ? currentValue.includes(val.id) : false} onChange={handleChange} name={val.id} color={(val.style && ['accept', 'reject'].includes(val.style)) ? val.style : 'secondary'} />
            }
            label={val.label}
          />}
        )}
        </FormGroup>
      </FormControl>
  )
})

const ReactedSticker = ({review}) => {
  let bg = '#e5b603';
  let text = 'Response submitted';
  return <Tooltip title={(review.userEmailResponded ? `by ${review.userEmailResponded} ` : " ") + (review.respondedAt ? ((new Date(review.respondedAt.seconds * 1000)).toDateString() + " " + (new Date(review.respondedAt.seconds * 1000)).toLocaleTimeString()) : "")}><Box sx={{alignSelf:'end'}}><Typography variant='subtitle1' sx={{ fontSize:'12px', background:bg, color: '#FFF', borderRadius:'4px', paddingLeft: '6px', paddingRight: '6px'}}>{text}</Typography></Box></Tooltip>
}

const ErrorValue = ({}) => {
  //return isDevOrAdmin ? <Typography variant='body2' sx={{ color:"#FF5714" }}>Incompatible value</Typography> : ""
  return <Typography variant='body2' sx={{ color:"#FF5714" }}>Incompatible value</Typography>
}

const DropDownMenu = React.memo(({accountId, user, reviewId, isDeleted}) => {
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const [deleted, setDeleted] = useState(isDeleted);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    setDeleteTrigger(true);
  }
  
  useEffect(() => {
    if (!deleteTrigger) return () => { };
    Data.getInstance().deleteReview({userId: user.id, userEmail: user.email, accountId: accountId, reviewId: reviewId} ).then(() => {
      setDeleteTrigger(false)
      setDeleted(true)
    }).catch((error) => {
      console.error("Couldn't delete review", error)
      setDeleteTrigger(false)
    });
  }, [deleteTrigger]);

  return (
    <Box sx={{padding:'0px'}}>
      {deleteTrigger && <Typography variant='subtitle'>Deleting...</Typography>}
      {deleted && <Typography variant='subtitle' color='error'>Deleted!</Typography>}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
          {!deleted && <MenuItem key={"delete"} onClick={handleDelete}>
            Delete
          </MenuItem>}
      </Menu>
    </Box>
  );
});