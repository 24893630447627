import { useState, useEffect } from "react";

export const handleDoneKeyPress = (event) => {
  if (event.key === 'Enter') {
    event.target.blur();
  }
};

export const useDebounce = (cb, delay) => {
  const [debounceValue, setDebounceValue] = useState(cb);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(cb);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [cb, delay]);
  return debounceValue;
}