import { Box, Typography, ToggleButtonGroup, ToggleButton, Link } from "@mui/material";
import React, { useState } from "react";
import { PyCodeBox, TsCodeBox, ApiJsonBox, JsonBox } from "./CodePreviews";

export const RequestPreview = React.memo(({accountId, apiKey, dummyReviewRequest, dummyReviewRequestResponse}) => {
  
  const [corePreviewSelection, setCorePreviewSelection] = useState();

  return <Box sx={{display:'flex', flexDirection: 'column', width:'100%', px:'12px'}}>
  <Typography variant="h6" sx={{ marginBottom: '12px', background:'#114B5F', color:'#fff', fontWeight: '600', borderRadius:'4px', px:'20px', py:'6px' }}>Request to send</Typography>
    <Box sx={{display:'flex', flexDirection:'column', gap: '8px', mt:'0px', px:{xs: '10px', sm: '20px'}}}>
      <Box sx={{display:'flex', gap: '6px', flexWrap:'wrap'}}>
        <Typography variant="subtitle2" sx={{ color: corePreviewSelection ? '#999' : '#E26D5C', marginBottom: '0px', px:'0px' }}>Select your integration</Typography>
        <Typography variant="subtitle2" sx={{ color: '#888', mb: '0px' }} component={Link} href="https://docs.gotohuman.com" target="_blank">(More in our docs)</Typography>
      </Box>
      <ToggleButtonGroup
      size='small'
      sx={{mr:'10px', mb:'10px', flexWrap:'wrap'}}
        value={corePreviewSelection}
        exclusive={true}
        onChange={(event, newValue) => setCorePreviewSelection(old => (!newValue || "" === newValue) ? old : newValue)}
      >
        {[['py', 'Python SDK'], ['ts', 'JS/TS SDK'], ['api', 'API'], ['make', 'Make']].map(val => <ToggleButton sx={{minWidth:{xs:'50px', sm:'80px'}, textWrap:'nowrap'}} key={val[0]} value={val[0]}>{val[1]}</ToggleButton>)}
      </ToggleButtonGroup>
    </Box>
    {(corePreviewSelection === 'py') && <Box sx={{display:'flex', flexDirection: 'column', gap: '0px', background:'#fff', borderRadius:'8px', mt:'4px', px:'10px', py:'20px'}}>
      <Box display={'flex'} flexDirection={'column'} sx={{height:'60px', justifyContent:'space-around', marginBottom: '10px', overflowX:'auto', background:'#444', borderRadius:'4px', px:'12px', py:'2px',
        '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 2,},'::-webkit-scrollbar': {height: '8px'}
      }}>
        <Box display={'flex'} sx={{gap:'14px'}}>
          <Typography variant="body1" sx={{flex: 1, color:'#e5b603', fontFamily:'monospace', fontWeight: '600', textWrap:'nowrap' }}>pip install gotohuman</Typography>
        </Box>
        <Box display={'flex'} sx={{alignItems:'center'}}>
          <Typography variant="subtitle2" sx={{ color: '#aaa', mr:'14px', textWrap:'nowrap' }}>Env. var:</Typography>
          <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600', textWrap:'nowrap' }}>GOTOHUMAN_API_KEY=</Typography>
          <ApiKeyField accountId={accountId} apiKey={apiKey} />
        </Box>
      </Box>
      <PyCodeBox apikey={apiKey} formId={dummyReviewRequest?.formId || ""} obj={dummyReviewRequest} />
    </Box>}
    {(corePreviewSelection === 'ts') && <Box sx={{display:'flex', flexDirection: 'column', gap: '0px', background:'#fff', borderRadius:'8px', mt:'4px', px:'10px', py:'20px'}}>
      <Box display={'flex'} flexDirection={'column'} sx={{height:'60px', justifyContent:'space-around', marginBottom: '10px', overflowX:'auto', background:'#444', borderRadius:'4px', px:'12px', py:'2px',
        '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 2,},'::-webkit-scrollbar': {height: '8px'}
      }}>
        <Box display={'flex'} sx={{gap:'14px'}}>
          <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600', textWrap:'nowrap' }}>npm i gotohuman</Typography>
        </Box>
        <Box display={'flex'} sx={{alignItems:'center'}}>
          <Typography variant="subtitle2" sx={{ color: '#aaa', mr:'14px', textWrap:'nowrap' }}>Env. var:</Typography>
          <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600', textWrap:'nowrap' }}>GOTOHUMAN_API_KEY=</Typography>
          <ApiKeyField accountId={accountId} apiKey={apiKey} />
        </Box>
      </Box>
      <TsCodeBox apikey={apiKey} formId={dummyReviewRequest?.formId || ""} obj={dummyReviewRequest} />
    </Box>}
    {(corePreviewSelection === 'api') && <Box sx={{display:'flex', flexDirection: 'column', gap: '0px', background:'#fff', borderRadius:'8px', mt:'4px', px:'10px', py:'20px'}}>
      <Box display={'flex'} flexDirection={'column'} sx={{height:'60px', justifyContent:'space-around', marginBottom: '10px', overflowX:'auto', background:'#444', borderRadius:'4px', px:'12px', py:'2px',
        '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 2,},'::-webkit-scrollbar': {height: '8px'}
      }}>
        <Box display={'flex'} sx={{gap:'14px'}}>
          <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600', textWrap:'nowrap' }}>HTTP POST</Typography>
          <Typography sx={{color:'#f1f1f1', fontFamily:'monospace', fontWeight: '400' }}>https:/api.gotohuman.com/requestReview</Typography>
        </Box>
        <Box display={'flex'} sx={{gap:'14px', alignItems:'center'}}>
          <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600', textWrap:'nowrap' }}>Headers[x-api-key]</Typography>
          <ApiKeyField accountId={accountId} apiKey={apiKey} />
        </Box>
      </Box>
      <ApiJsonBox obj={dummyReviewRequest} />
    </Box>}
    {(corePreviewSelection === 'make') && <Box sx={{display:'flex', flexDirection: 'column', gap: '0px', background:'#fff', borderRadius:'8px', mt:'4px', px:'10px', py:'20px'}}>
      <Box display={'flex'} flexDirection={'column'} sx={{height:'60px', justifyContent:'space-around', marginBottom: '10px', overflowX:'auto', background:'#444', borderRadius:'4px', px:'12px', py:'2px',
        '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 2,},'::-webkit-scrollbar': {height: '8px'}
      }}>
        <Box display={'flex'} sx={{gap:'14px', alignItems:'center'}}>
          <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600', textWrap:'nowrap' }}>API key</Typography>
          <ApiKeyField accountId={accountId} apiKey={apiKey} />
        </Box>
      </Box>
      <Typography variant="subtitle2" sx={{ color: '#aaa', mb: '0px', textWrap:'nowrap' }} component={Link} href="https://docs.gotohuman.com/Integrations/make-com" target="_blank">-&gt; Read our Make integration guide</Typography>
    </Box>}
    {corePreviewSelection && <><Typography variant="subtitle2" sx={{ color:'#999', mt:'24px', marginBottom: '0px', px:'0px' }}>Response:</Typography>
    <Box sx={{display:'flex', flexDirection: 'column', gap: '0px', background:'#fff', borderRadius:'8px', mt:'14px', px:'10px', py:'20px'}}>
      <Box display={'flex'} flexDirection={'column'} sx={{marginBottom: '10px', background:'#444', borderRadius:'4px', px:'12px', py:'4px'}}>
        <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600', textWrap:'nowrap' }}>HTTP 200</Typography>
      </Box>
      <JsonBox obj={dummyReviewRequestResponse} />
    </Box></>}

</Box>
});

const ApiKeyField = ({accountId, apiKey}) => {
  const [revealKey, setRevealKey] = useState(false);
  return <>
    {accountId && apiKey && <Box sx={{cursor: revealKey ? 'text' : 'pointer', borderBottom: '1px dotted #ccc'}} onClick={()=>setRevealKey(true)}><Typography variant="body1" sx={{color:'#f1f1f1', fontFamily:'monospace', fontWeight: '400', textWrap:'nowrap' }}>{revealKey ? apiKey : (apiKey.substring(0,2)+'*************')}</Typography></Box>}
    {(!accountId) && <Typography variant="caption" sx={{color:'#f1f1f1', fontWeight: '400', textWrap:'nowrap' }}><i>Create your form first</i></Typography>}
  </>
}