import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, ListItemButton, Divider, TextField, Button, useMediaQuery, useTheme, Popover, Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, DialogActions, Link, Select, ToggleButtonGroup, ToggleButton, Checkbox, Switch } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Data from './Data';
import ReviewForm from './ReviewForm';
import { REVIEW_STATE } from './ReviewStates';
import { Timestamp } from 'firebase/firestore';
import { AccountContext, UserContext } from './AccountFrame';
import CircularProgress from '@mui/material/CircularProgress';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DoneIcon from '@mui/icons-material/Done';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UI_TYPE } from './UiTypes';
import { TEMPLATES } from './FormTemplates';
import FirebaseManager from './FirebaseManager';
import { Helmet } from 'react-helmet';
import InfoSvg from './components/SvgInfo';
import { handleDoneKeyPress, useDebounce } from './utils/ui-utils';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { RuleRow } from './components/editor/RuleRow';
import { AvailableFieldsList } from './components/editor/AvailableFieldsList';
import { AddedFieldsList } from './components/editor/AddedFieldsList';
import { AddFieldDialog } from './components/editor/AddFieldDialog';
import { AddRuleDialog } from './components/editor/AddRuleDialog';
import { JsonBox, ApiJsonBox, PyCodeBox, TsCodeBox } from './components/editor/CodePreviews';
import { DropDownMenu } from './components/editor/DropDownMenu';
import { RequestPreview } from './components/editor/RequestPreview';

const DUMMY_REVIEW_ID = "rev123456abcdef"
const DUMMY_ACCOUNT_ID = "acc123456abcdef"

const FormEditor = ({formToEdit = null}) => {
  const accountCtx = useContext(AccountContext);
  const accountId = accountCtx?.account?.id
  const navigate = useNavigate();
  const location = useLocation();
  const isXSmallScreenOrLess = useMediaQuery(useTheme().breakpoints.down("sm"))

  const [key, setKey] = useState();
  const [form, setForm] = useState(formToEdit || {name: "My Review Form"});
  const [debouncedForm, setDebouncedForm] = useState();
  const [changeId, setChangeId] = useState();
  const [savedChangeId, setSavedChangeId] = useState();
  const pendingChanges = changeId !== savedChangeId
  const [saveTrigger, setSaveTrigger] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [dummyReviewRequest, setDummyReviewRequest] = useState();
  const dummyReviewRequestResponse = {reviewId: DUMMY_REVIEW_ID, gthLink: `https://app.gotohuman.com/accounts/${DUMMY_ACCOUNT_ID}/reviews/${DUMMY_REVIEW_ID}`, ...(form?.publicLinkEnabled && {gthPublicLink: `https://app.gotohuman.com/reviews/${DUMMY_ACCOUNT_ID}/${DUMMY_REVIEW_ID}`})}
  const [dummyFullReview, setDummyFullReview] = useState();
  const [webhookPayload, setWebhookPayload] = useState();
  const [debouncedWebhookPayload, setDebouncedWebhookPayload] = useState();
  const [previewResponseValues, setPreviewResponseValues] = useState({});
  const formComplete = form && form.name && (form.name.trim().length > 0) && form.webhook && (form.webhook.trim().length > 0 && form.fields && (Object.keys(form.fields).length > 0))
  // const dummyFullReview = (form && dummyReviewRequest) ? {...dummyReviewRequest, ...{ state: REVIEW_STATE.PENDING, createdAt: Timestamp.now(), form: form }} : null
  const formFields = form?.fields
  const formFieldOrder = form?.fieldOrder
  const formRules = form?.rules
  const formId = form?.docId
  const formName = form?.name
  const formDummyRequest = form?.dummyRequest
  
  const isDialogHashPresent = location.hash === '#d';
  const openComponentAdd = isDialogHashPresent && location.state && location.state.type === 'comp' ? location.state.data : null;
  const openRuleAdd = isDialogHashPresent && location.state && location.state.type === 'rule' ? location.state.data : null;

  useEffect(() => {
    if (!accountId) return;
    setKey(null);
    const subscription = Data.getInstance().fetchApiKey(`Accounts/${accountId}`)
        .subscribe((key) => setKey(key));
    return () => {
      subscription.unsubscribe();
    };
  }, [accountId]);

  useEffect(() => {
    if (!saveTrigger) return () => { };
    setSuccessMsg(null)
    let subscription;
    if (formId) {
      subscription = Data.getInstance().updateForm(accountId, formId, form)
      .subscribe((x) => {
        setSaveTrigger(false);
        setSavedChangeId(changeId)
        setSuccessMsg("Updated your form")
        },
      );
    } else {
      if (accountId) {
        subscription = Data.getInstance().addForm(accountId, form)
          .subscribe((newId) => {
            setSaveTrigger(false);
            setSavedChangeId(changeId)
            setForm(prev => {return {...prev, docId: newId}})
            setSuccessMsg('Created ' + newId)
            FirebaseManager.trackEvent("createNewForm")
            },
          );
      } else {
        //save locally
        try {
          const jsonString = JSON.stringify(form);
          localStorage.setItem(Data.LOCAL_STORAGE_FORM_KEY, jsonString);
          FirebaseManager.trackEvent("precreateNewForm")
          navigate(`/signin?creatingForm=true`);
        } catch (error) {
          console.error("Error saving form to local storage", error);
        }
      }
    }

    return () => {
      if (subscription)
        subscription.unsubscribe();
    };
  }, [accountId, saveTrigger]);

  const debounceFormVal = useDebounce(form, 750);

  useEffect(() => {
    setDebouncedForm(form);
  }, [debounceFormVal]);

  const debounceWebhookVal = useDebounce(webhookPayload, 750);

  useEffect(() => {
    setDebouncedWebhookPayload(webhookPayload);
  }, [debounceWebhookVal]);
      
  useEffect(() => {
    const generateDummyReview = (formId, formFields, formDummyRequest) => {
      const dummyReview = {
        formId: formId || "123456abcdef",
        ...{fields: {}},
        meta: formDummyRequest?.meta || {metaIsOptional: "You'll receive this back in the webhook"}
      };
    
      for (const [key, field] of Object.entries(formFields || {})) {
        const cardSample = {
          header: "Lirum Larum",
          subtitle: "My subtitle",
          stats: [
            {key: "Item1", value: "Value 1"},
            {key: "Item2", value: "Value 2"},
            {key: "Item3", value: "Value 3"}
          ]
        }
        const fileLink = formDummyRequest?.fields[key] || {
          url: "https://www.sbs.ox.ac.uk/sites/default/files/2019-01/cv-template.pdf",
          label: "Open This File"
        };
        const urlLink = formDummyRequest?.fields[key] || {
          url: "https://openai.com/news/",
          label: "Go to link"
        };
          switch (field.uiType) {
            case UI_TYPE.HEADER:
            case UI_TYPE.LABEL:
            case UI_TYPE.TEXT_SHORT:
              dummyReview.fields[key] = formDummyRequest?.fields[key] || "Lirum Larum Ipsum Dolor Sit Amet";
              break;
            case UI_TYPE.TEXT:
              dummyReview.fields[key] = formDummyRequest?.fields[key] || "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.\nEx unde aspernatur est quia rerum vel sint explicabo qui voluptatem omnis? Sit delectus aliquid vel facere fugit ut natus illo in alias voluptatem et animi nihil aut perferendis autem. Et exercitationem esse ad rerum consectetur est nisi earum qui exercitationem consequuntur ab omnis error qui quia asperiores. Est minus totam id temporibus omnis id deleniti galisum ab quis quis sed repellendus voluptas.\nQui magnam nihil et totam quis et exercitationem sunt 33 nemo atque. Et nihil nisi a quam quisquam aut cupiditate omnis est facilis quas. A alias provident ut maiores illo qui temporibus possimus aut eligendi voluptatum ea autem quod non animi enim. Ea iste minima aut aperiam impedit ab distinctio suscipit.\nAut iusto dignissimos et cumque magnam vel placeat sint qui excepturi dolores et veniam temporibus eos voluptatum nulla et ullam asperiores. Ut sint sapiente et assumenda ipsum aut vero dolor aut quia alias est architecto commodi est quas corrupti vel expedita aliquid.";
              break;
            case UI_TYPE.MARKDOWN:
              dummyReview.fields[key] = formDummyRequest?.fields[key] || "# Header for my article\n## Summary\nA *summarization* of the topics presented.  \nLorem ipsum dolor sit amet. Vel exercitationem recusandae vel beatae veniam et molestiae architecto qui animi rerum non quia aliquam in aspernatur aspernatur. Eum illo dolor ut quisquam optio et nisi omnis eos accusantium voluptatem qui expedita quia et temporibus quam. Est blanditiis ipsa quo odit consectetur qui modi optio sed sunt tempore id perspiciatis debitis?\n## Part 1\nEt doloremque provident sit dicta magni et odit labore quo expedita consequuntur sit consectetur dolores aut delectus aspernatur qui eaque quam. Quo error enim ea quis dolore est voluptatibus odio?";
              break;
            case UI_TYPE.EMAIL_HTML:
              dummyReview.fields[key] = formDummyRequest?.fields[key] || "<div>Hello humans!</div><br/><div>Lorem ipsum dolor sit amet. Et voluptas error qui molestias consequatur ut animi animi a reprehenderit galisum cum nemo quos vel explicabo voluptatum.</div><br/><div><a href=\"https://docs.gotohuman.com\" style=\"color: #114B5F; text-decoration:none;\">Click here for more</a><br/><br/><br/><br/><br/>Footer</div>";
              break;
            case UI_TYPE.FILE_LINK:
              dummyReview.fields[key] = formDummyRequest?.fields[key] || fileLink;
              break;
            case UI_TYPE.URL_LINK:
              dummyReview.fields[key] = formDummyRequest?.fields[key] || urlLink;
              break;
            case UI_TYPE.FILE_LINKS:
              dummyReview.fields[key] = formDummyRequest?.fields[key] || ([1,2,3].map(i=> fileLink));
              break;
            case UI_TYPE.URL_LINKS:
              dummyReview.fields[key] = formDummyRequest?.fields[key] || ([1,2,3].map(i=> urlLink));
              break;
            case UI_TYPE.RATING:
              dummyReview.fields[key] = formDummyRequest?.fields[key] || 4.5;
              break;
            case UI_TYPE.IMAGE_GRID:
              dummyReview.fields[key] = formDummyRequest?.fields[key] || [1,2].map(i=>{return {
                label: 'Image '+i, url: 'https://cdn.prod.website-files.com/6605a2979ff17b2cd1939cd4/66fe9dc570ff5555b44f4fae_landscape-sample-img.jpg'
              }});
              break;
            case UI_TYPE.CARD_GRID:
              dummyReview.fields[key] = formDummyRequest?.fields[key] || [cardSample, cardSample, cardSample];
              break;
            case UI_TYPE.BULLETED_LIST:
              dummyReview.fields[key] = formDummyRequest?.fields[key] || [
                "Lorem ipsum dolor sit amet. Ut enim consectetur qui quia quidem ab maxime",
                "Eum eveniet internos ea consequuntur nihil id libero illo eum repudiandae labore",
                "Et sint nemo est quaerat necessitatibus et magnam aperiam"
              ];
              break;
            case UI_TYPE.CHECKBOXES:
              if (!field.values) {
                dummyReview.fields[key] = formDummyRequest?.fields[key] || [
                  {
                    id: 'box1', label: 'Checkbox 1'
                  },
                  {
                    id: 'box2', label: 'Checkbox 2'
                  }
                ]
              }
              break;
            case UI_TYPE.DROPDOWN:
              if (!field.values) {
                dummyReview.fields[key] = formDummyRequest?.fields[key] || [1,2,3,4,5,6,7,8].map(i => {return {id: 'option'+i, label: 'Option '+i}})
              }
              break;
            case UI_TYPE.BUTTON_SELECT:
              if (!field.values) {
                dummyReview.fields[key] = formDummyRequest?.fields[key] || [
                  {
                    id: 'choice1', label: 'Choice 1'
                  },
                  {
                    id: 'choice2', label: 'Choice 2'
                  }
                ]
              }
              break;
            /* case 'staticText':
              dummyReview.fields[key] = field.value;
              break; */
            /* case 'cardSelect':
              break; */
            /* default:
              dummyReview.fields[key] = null; // Default case for unknown uiTypes */
          }
      }
    
      console.log("generated " + JSON.stringify(dummyReview))
      return dummyReview;
    }
    const formFields = debouncedForm?.fields
    const formId = debouncedForm?.docId
    const formDummyRequest = debouncedForm?.dummyRequest
    const dummyReq = generateDummyReview(formId, formFields, formDummyRequest);
    setDummyReviewRequest(dummyReq)
    setDummyFullReview((debouncedForm && dummyReq) ? {...dummyReq, ...{ state: REVIEW_STATE.PENDING, createdAt: Timestamp.now(), form: debouncedForm }} : null)
  }, [debouncedForm]);  
    
  useEffect(() => {
    setWebhookPayload({
      accountId: accountId || DUMMY_ACCOUNT_ID,
      reviewId: DUMMY_REVIEW_ID,
      formId: formId || "form123456abcdef",
      formName: formName ? formName : "My Form",
      respondingUser: "jess@acme.org",
      respondedAt: "2024-10-05T14:48:00.000Z",
      responseValues: previewResponseValues,
      meta: formDummyRequest?.meta || {metaIsOptional: "You'll receive this back in the webhook"}
    })
  }, [accountId, formId, formName, formDummyRequest, previewResponseValues]);

  useEffect(() => {
    if (isXSmallScreenOrLess) return () => {}
    window.TallyConfig = {
      formId: "3qEy0k",
      popup: {
        hideTitle: true,
        autoClose: 2000,
        open: {
          trigger: "time",
          ms: 10000,
        },
        "showOnce": true,
      },
    };
  }, []);

  const onPreviewResponseChanged = useCallback((values) => {
    setPreviewResponseValues(values);
  }, [])

  const onCompAddClick = useCallback((uiType) => {
    navigate('#d', { state: { type: 'comp', data: [uiType, null] } });
  }, [])

  const onCompAdded = useCallback((compInfoToAdd) => { //or edited
    updateForm(prev => {
      const change = {...prev, ...{fieldOrder: [...(prev?.fieldOrder || []), ...(!prev||!prev.fields||!prev.fields[compInfoToAdd.key] ? [compInfoToAdd.key] : [])], fields: {...(prev?.fields || {}), ...{[compInfoToAdd.key]: compInfoToAdd.object}}}}
      return change;
    })
  }, [])

  const onRuleAdded = useCallback((ruleToAdd) => { //or edited
    updateForm(prev => {
      const prevRules = prev?.rules || []
      const oldIndex = prevRules.findIndex(rule => rule.id === ruleToAdd.id)
      const newRules = prevRules.filter(rule => rule.id !== ruleToAdd.id).toSpliced(oldIndex >= 0 ? oldIndex : prevRules.length, 0, ruleToAdd)
      const change = {...prev, ...{rules: newRules}}
      return change;
    })
  }, [])

  const onCompEditClick = useCallback((fieldId, field) => {
    navigate('#d', { state: { type: 'comp', data: [field.uiType, {key: fieldId, object: field}] } });
  }, [])

  const onCompMoveUp = useCallback((fieldId) => {
    onMove(fieldId, true)
  }, [])

  const onCompMoveDown = useCallback((fieldId) => {
    onMove(fieldId, false)
  }, [])

  const onDeleteField = useCallback((fieldId) => {
    updateForm(prev => {
      const newfields = {...prev.fields};
      delete newfields[fieldId]
      const change = {...prev, ...{fieldOrder: prev.fieldOrder.filter(el=>el!==fieldId), fields: newfields}}
      return change;
    })
  }, [])

  const onMove = useCallback((fieldId, up) => {
    updateForm(prev => {
      const currentIndex = prev.fieldOrder.findIndex(el => el === fieldId)
      const change = {...prev, ...{fieldOrder: prev.fieldOrder.filter(el=>el!==fieldId).toSpliced(currentIndex+(up ? -1 : 1),0,fieldId)}}
      return change;
    })
  }, [])

  const onTemplateSelected = useCallback((form) => {
    updateForm(() => form)
  }, [])

  const onChangeFormName = useCallback((name) => {
    updateForm(form => {return {...(form && form), name: name};})
  }, [])

  const onChangeFormWebhook = useCallback((webhook) => {
    updateForm(form => {return {...form, webhook: webhook};})
  }, [])

  const onChangeCreatePublicLink = useCallback((enable) => {
    updateForm(form => {return {...(form && form), publicLinkEnabled: enable};})
  }, [])

  const updateForm = (updateFn) => {
    setForm((form) => {
      const updatedForm = updateFn(form);
      return updatedForm;
    });
    setSavedChangeId(Date.now()+"");
  };

  const onAddRuleClick = useCallback((rule) => {
    navigate('#d', { state: { type: 'rule', data: [null] } });
  }, [])

  const onEditRuleClick = useCallback((rule) => {
    navigate('#d', { state: { type: 'rule', data: [rule] } });
  }, [])

  const onDeleteRuleClick = useCallback((rule) => {
    updateForm(prev => {
      return {...prev, ...{rules: (prev?.rules || []).filter(arrRule => arrRule.id !== rule.id)}}
    })
  }, [])

  return (<>
  {!isXSmallScreenOrLess && <Helmet>
    <script src="https://tally.so/widgets/embed.js" async />
  </Helmet>}
  <Box sx={{
    width: '100%',
    height: '100%',
    pb: '64px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%', maxWidth: '1160px', px: {xs: '0px', sm: '24px'}, paddingTop: {xs: '14px', lg: '40px'}, paddingBottom: '60px', }}>
      <Box sx={{ display: 'flex', justifyContent:'space-between', mb:'10px' }}>
        {/* <Typography variant="h4" sx={{ fontWeight: '600' }}>{formId ? 'Update Review Form':'Create Review Form'}</Typography> */}
        <Box sx={{
            display: 'flex',
            // mb: '16px',
            // px: {xs: '12px', md: '22px'},
            // py:'12px',
            width:'100%', 
            minWidth:'100px',
            maxWidth:'640px'
           }}>
              {/* <Typography variant="h6" sx={{ fontWeight: '600' }}>Name</Typography> */}
              <TextField id="input-form-name" variant="standard" fullWidth placeholder='Enter name' sx={{}} value={form?.name || ""} onChange={(ev) => onChangeFormName(ev.target.value)}
                inputProps={{enterKeyHint: 'done', style: {fontSize: '32px', fontWeight: '600' }}}
                onKeyDown={handleDoneKeyPress}
              />
          </Box>
        {formId && accountId && <DropDownMenu accountId={accountId} formId={formId} isDeleted={form.deleted} />}
      </Box>
      {/* {!formId && <Typography variant="subtitle2" sx={{ my: '10px', color:'#666', px:'0px' }}>Create a form to present to human supervisors when your AI app needs input or approval. You can dynamically inject the right data into components with every request for review you send.<br/>Perfect to review AI-generated content or agent tool calls, and to see additional data as context for decision-making.</Typography>} */}
        <Box display={'flex'} gap={'8px'} flexWrap={'wrap'} sx={{my:'14px'}}>
          <Typography variant="subtitle2" sx={{ color: '#71717a', textWrap:'nowrap' }} component={Link} href="#preview">↓ Preview form</Typography>
          <Typography variant="subtitle2" sx={{ color: '#71717a', textWrap:'nowrap' }} component={Link} href="#api-request">↓ API request</Typography>
          <Typography variant="subtitle2" sx={{ color: '#71717a', textWrap:'nowrap' }} component={Link} href="#webhook">↓ Webhook</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          mt: '16px',
        }}>
          {/* <Typography id='create' variant="h6" sx={{ marginBottom: '20px', mt: '0px', background:'#114B5F', color:'#fff', fontWeight: '600', borderRadius:'4px', px:'20px', py:'6px' }}>{formId ? 'Update' : 'Create'} Form</Typography>
          
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: '16px',
            background:'#fff',
            borderRadius:'8px',
            px: {xs: '12px', md: '22px'},
            py:'12px',
            maxWidth:'640px'
           }}>
              <TextField id="input-form-name" variant="standard" fullWidth placeholder='Enter name' sx={{}} value={form?.name || ""} onChange={(ev) => onChangeFormName(ev.target.value)}
                inputProps={{enterKeyHint: 'done', style: {fontSize: 18, fontFamily:''}}}
                onKeyDown={handleDoneKeyPress}
              />
          </Box> */}
          {!formId && <TemplateSelector disabled={saveTrigger} onTemplateSelected={onTemplateSelected} />}
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            background:'#fff',
            borderRadius:'8px',
            mb: '16px',
            px: {xs: '12px', md: '22px'},
            py:'12px',
          }}>
            <Typography variant="h6" sx={{ marginBottom: '16px', fontWeight: '700' }}>Add Fields</Typography>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: '32px',}}>
              <AvailableFieldsList onCompAddClick={onCompAddClick} />
              <AddedFieldsList formFieldOrder={formFieldOrder} formFields={formFields} onEdit={onCompEditClick} onDelete={onDeleteField} onMoveDown={onCompMoveDown} onMoveUp={onCompMoveUp} />
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            background:'#fff',
            borderRadius:'8px',
            mb: '16px',
            px: {xs: '12px', md: '22px'},
            py:'12px',
          }}>
            <Typography variant="h6" sx={{ mb: '16px', fontWeight: '700' }}>Settings</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap', marginBottom: '16px', alignItems:'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems:'center', width:'300px' }}>
                <Typography variant="subtitle2" sx={{ mr:'6px' }}>Webhook for submissions</Typography>
                <InfoSvg data-tooltip-id="app-tooltip" data-tooltip-content="This is where we send the result of each review when it is submitted." />
              </Box>
              <TextField id="input-form-webhook" variant="standard" placeholder='https://...' inputProps={{style: {fontFamily:'monospace', enterKeyHint: 'done'}}} sx={{mx:'0px',fontSize:'14px', flex:1, minWidth:'200px'}} value={form?.webhook || ""} onChange={(ev) => onChangeFormWebhook(ev.target.value)} onKeyDown={handleDoneKeyPress} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap', marginBottom: '16px', alignItems:'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems:'center', width:'300px' }}>
                <Typography variant="subtitle2" sx={{ mr:'6px' }}>Public links</Typography>
                <InfoSvg data-tooltip-id="app-tooltip" data-tooltip-content="In the response of a review request you will receive a 'gthPublicLink'. Anyone with that link is allowed to complete this review. Share this link with care."/>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems:'center' }}>
                <Switch
                  checked={form?.publicLinkEnabled || false}
                  onChange={event => onChangeCreatePublicLink(event.target.checked)}
                />
                <Typography variant="body2" sx={{  }}>Create shortlived public links</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            background:'#fff',
            borderRadius:'8px',
            px: {xs: '12px', md: '22px'},
            py:'12px',
          }}>
            <Typography variant="h6" sx={{ mb: '16px', fontWeight: '700' }}>Visibility rules</Typography>
            <Box sx={{display: 'flex', flexDirection:'column', alignItems:'start', gap: '12px',}}>
              {formRules && (formRules.length > 0) && formRules.map(rule => <RuleRow key={rule.id} rule={rule} onEditClick={onEditRuleClick} onDeleteClick={onDeleteRuleClick} />)}
            </Box>
            <Box>
              {(!formFieldOrder || formFieldOrder.length == 0) && <Box><Typography variant="subtitle2" sx={{ px: '34px', color:'#ddd' }}>Add fields first to add rules when to show/hide them</Typography></Box>}
              {(formFieldOrder && (formFieldOrder.length > 0)) && <Button startIcon={<AddOutlinedIcon />} onClick={onAddRuleClick} sx={{my:'12px'}}>Add rule</Button>}
            </Box>
          </Box>
          <Typography id='preview' variant="h6" sx={{ marginBottom: '12px', mt: '80px', background:'#114B5F', color:'#fff', fontWeight: '600', borderRadius:'4px', px:'20px', py:'6px' }}>Preview</Typography>
          <Typography variant="subtitle2" sx={{ color:'#999', marginBottom: '16px', px:'20px' }}>This is how the form will be shown to your supervising team for the review requests you send:</Typography>
          <Divider sx={{mb:'40px'}} />
          {dummyFullReview ? <ReviewForm review={dummyFullReview} previewOnly={true} onResponseValuesChanged={onPreviewResponseChanged} /> : <Box><Typography variant="body1" color="secondary" sx={{ p: '34px' }}>Preview will appear here</Typography></Box>}
          <Divider sx={{my:'40px'}} />
          {form && <Box sx={{display:'flex', flexDirection: {xs: 'column', md: 'row'}, gap: '0px', mt:'0px', px:'0px'}}>
            <Box id='api-request' sx={{flex:1, width:{xs: '100%', md: '50%'}, mt:'20px'}}>
            <RequestPreview accountId={accountId} apiKey={key} dummyReviewRequest={dummyReviewRequest} dummyReviewRequestResponse={dummyReviewRequestResponse} />
            </Box>
            <Box id='webhook' sx={{display:'flex', flexDirection: 'column', flex:1, width:{xs: '100%', md: '50%'}, px:'12px', mt:'20px'}}>
            <Typography variant="h6" sx={{ marginBottom: '12px', background:'#114B5F', color:'#fff', fontWeight: '600', borderRadius:'4px', px:'20px', py:'6px' }}>Webhook</Typography>
            <Typography variant="subtitle2" sx={{ color:'#999', marginBottom: '0px', px:{xs: '10px', sm: '20px'} }}>Webhook you'll receive when your users submit above's review:</Typography>
              {/* <Typography variant="subtitle2" sx={{ color: '#aaa', mb: '32px' }} component={Link} href="https://docs.gotohuman.com" target="_blank">Read our docs</Typography> */}
              <Box sx={{display:'flex', flexDirection: 'column', gap: '0px', background:'#fff', borderRadius:'8px', mt:'24px', px:'10px', py:'20px'}}>
              <Box display={'flex'} flexDirection={'column'} sx={{height:'60px', justifyContent:'space-around', marginBottom: '10px', overflowX:'auto', overflowY:'hidden', background:'#444', borderRadius:'4px', px:'12px', py:'4px',
                '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 2,},'::-webkit-scrollbar': {height: '8px'}}}>
              {((form?.webhook || "").length == 0) && <Typography variant="body1" sx={{color:'#e1e1e1', fontWeight: '400', textAlign:'center', fontSize:'12px' }}>Enter your webhook URL above</Typography>}
                {((form?.webhook || "").length > 0) && <><Box display={'flex'} sx={{gap:'14px'}}>
                  <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600', textWrap:'nowrap' }}>HTTP POST</Typography>
                  <Typography variant="body1" sx={{color:'#f1f1f1', fontFamily:'monospace', fontWeight: '400' }}>{form?.webhook || ""}</Typography>
                </Box>
                <Box display={'flex'}>
                  <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600' }}>&nbsp;</Typography>
                </Box></>}
              </Box>
              <JsonBox obj={debouncedWebhookPayload} />
              </Box>
            </Box>
          </Box>}
        </Box>
    </Box>
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: '#f1f1f1',
        borderTop: 1,
        borderColor: 'divider',
        p: 0,
      }}
    ><Box sx={{display:'flex', justifyContent:'center', minHeight:'80px',}}>
      <Box sx={{display:'flex', flexDirection:'row', width: '90%', maxWidth: '1160px', justifyContent:'end', alignItems:'center', gap:'24px', px: {xs: '0px', sm: '24px'}, py: {xs: '12px', lg: '24px'}}}>
        {(successMsg || pendingChanges) && <Typography variant="subtitle2" color={pendingChanges ? "error" : "secondary"} sx={{flex:'1', textAlign:'end',  fontSize: {xs: '12px', sm: '14px'} }}>{pendingChanges ? ('You have unsaved changes!'+(formComplete ? '' : ' (But missing name, webhook or fields)')) : successMsg}</Typography>}
        <Button variant="contained" color="accent" sx={{borderRadius: '8px'}} disabled={!formComplete || saveTrigger || !pendingChanges} onClick={()=>{setSaveTrigger(true);}} startIcon={(saveTrigger) ? <CircularProgress color="approve" size={24} /> : <DoneIcon />}>{formId ? 'Update' : 'Create'}&nbsp;Form</Button>
      </Box>
      </Box>
    </Box>
  </Box>
  <AddFieldDialog key={openComponentAdd ? 'comp-add-dialog-open' : 'comp-add-dialog-closed'} openComponentAdd={openComponentAdd != null} onClose={() => navigate(-1)} uiType={openComponentAdd && openComponentAdd[0]} compToEdit={openComponentAdd && openComponentAdd[1]} formFieldOrder={formFieldOrder} onConfirm={onCompAdded} />
  <AddRuleDialog key={openRuleAdd ? 'rule-add-dialog-open' : 'rule-add-dialog-closed'} openRuleAdd={openRuleAdd != null} onClose={() => navigate(-1)} ruleToEdit={openRuleAdd ? openRuleAdd[0] : null} formFields={formFields} existingRuleTargetIds={(formRules||[]).map(rule=>rule.target?.fieldId)} onConfirm={onRuleAdded} />
  </>)
};

export default FormEditor;

const TemplateSelector = ({disabled, onTemplateSelected}) => {
  const [expanded, setExpanded] = useState(false)
  return <Box sx={{ display: 'flex', flexDirection: 'column', width: expanded ? '100%' : 'fit-content', mb:'18px', p:'12px', borderRadius:'8px', background:'#fff' }}>
            <Box color='#E26D5C' sx={{ display: 'flex', cursor:'pointer'}} onClick={() => setExpanded(old => !old)}><Typography variant='subtitle2' color={'inherit'} sx={{ px:'16px' }}>Start with a template</Typography>{expanded ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}</Box>
            {expanded && <Box sx={{ display: 'flex', gap:'8px', flexWrap:'wrap', mt:'10px' }}>
              {TEMPLATES.map(template => <Button key={template.name} variant="outlined" sx={{borderRadius: '8px', color:'#666'}} disabled={disabled} onClick={()=> onTemplateSelected(template.form)}>{template.name}</Button>)}
            </Box>}
          </Box>
}