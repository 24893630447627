import { Box, Typography, IconButton } from "@mui/material"
import { mapUiTypeToName } from "../formFields/field-utils"
import React from "react"
import { UI_TYPES_SHOW_STATIC } from "../../UiTypes";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import EditIcon from '@mui/icons-material/Edit';

export const AddedFieldsList = React.memo(({formFieldOrder, formFields, onEdit, onDelete, onMoveUp, onMoveDown}) => {
  return <Box sx={{display:'flex', flexDirection:'column', flex:1, gap:'10px'}}>
    <Typography variant="subtitle2" sx={{ marginBottom: '10px' }}>Added fields</Typography>
    {(!formFieldOrder || formFieldOrder.length == 0) && <Box><Typography variant="subtitle2" sx={{ p: '34px', color:'#ddd' }}>Added fields will appear here</Typography></Box>}
    {formFields && formFieldOrder && formFieldOrder.map((fieldId, index) => <Box key={fieldId} className="addedFieldBox" sx={{display:'flex', alignItems:'center', flexFlow:'wrap', background:'#eaeaea', px:'8px', py:'6px', gap:'8px', borderRadius:'8px'}}>
      <Box sx={{display:'flex', gap:'8px'}}>
        {!UI_TYPES_SHOW_STATIC.includes(formFields[fieldId].uiType) && <Typography variant='subtitle2' sx={{fontFamily:'monospace', background:'#444', color:'#f1f1f1', flexGrow:0, borderRadius:'3px', paddingLeft:'8px', paddingRight:'8px'}}>{fieldId}</Typography>}
        <Typography variant='subtitle2' sx={{ border:'1px solid #E26D5C', color:'#E26D5C', fontSize:'12px', lineHeight:'20px', borderRadius:'3px', flexGrow:0, paddingLeft:'8px', paddingRight:'8px'}}>{mapUiTypeToName(formFields[fieldId].uiType)}</Typography>
      </Box>
      <Box sx={{display:'flex', flexGrow: '1', justifyContent:'flex-end', gap:'6px', color:'#ccc', '@media (hover: hover)': {'.addedFieldBox:hover &':{ color: '#555' }}, '@media (hover: none)': {color: '#555'}}}>
        <IconButton size='small' color='inherit' onClick={() => onEdit(fieldId, formFields[fieldId])} ><EditIcon sx={{transform: 'scale(0.8)'}} /> </IconButton>
        <IconButton size='small' color='inherit' onClick={() => onDelete(fieldId)} ><DeleteOutlineOutlinedIcon sx={{transform: 'scale(0.8)'}} /> </IconButton>
        <IconButton size='small' color='inherit' disabled={index == 0} onClick={() => onMoveUp(fieldId)} ><ArrowUpwardOutlinedIcon sx={{transform: 'scale(0.8)'}} /> </IconButton>
        <IconButton size='small' color='inherit' disabled={index == (formFieldOrder.length-1)} onClick={() => onMoveDown(fieldId)} ><ArrowDownwardOutlinedIcon sx={{transform: 'scale(0.8)'}} /> </IconButton>
      </Box>
    </Box>)}
  </Box>
})